import React, {useCallback, useContext, useEffect, useState, useRef, useMemo} from 'react'
import MaterialReactTable from 'material-react-table'
import Modal from 'react-bootstrap/Modal'
import {
  Purchase_Order_Intial as initialValues,
  VendorInterface,
} from '../../modules/accounts/components/settings/SettingsModel'
import {
  createFullVendor,
  createInventoryItem,
  createShippingType,
  fetchCustomersWorkOrderUrl,
  fetchDummyAssignedItems,
  getInventoryData,
  inventoryListForPO,
  inventorySearchListForPO,
  need_to_order_inventory_url,
} from '../../modules/auth/core/_requests'
import TextField from '@mui/material/TextField'
import {Form, Formik, Field} from 'formik'
import * as Yup from 'yup'
import {
  createPurchaseOrder,
  currnetCompanyName,
  darkMessage,
} from '../../modules/auth/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import ErrorAlert, {ErrorType} from '../../modules/errors/ErrorAlert'
import SuccessAlert, {SuccessType} from '../../modules/errors/SuccessAlert'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {KTSVG, useDebounce} from '../../../_metronic/helpers'
import {InventoryManagement} from './InventoryManagement'
import {PurchaseOrderModel} from '../../modules/model/purchaseOrder'
import {LinearProgress} from '@mui/material'
import CreatableReactSelect from 'react-select/creatable'
import blank from '../../../_metronic/layout/components/header/blank.png'
import {NumberFormatter, onKeyDown} from './GlobalComponents'
import {InventoryModel} from '../../modules/model/Inventory'
import {Editor} from '@tinymce/tinymce-react'
import {PurchaseOrderFor} from './PurchaseOrderFor'
import SimpleDataTable from './SimpleDataTable'
import DataTable from 'react-data-table-component'
import {InventoryForm} from './InventoryForm'

const PurchaseOrderForm: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [newLoading, setNewLoading] = useState(false)
  const [anotherloading, setAnotherLoading] = useState(false)
  const {currentUser} = useAuth()
  const [options, setOptions] = useState<any>([])
  const [dropdown, setDropdown] = useState<any>([])
  const [modal, setModalShow] = React.useState<any>(false)
  const [workorders, setWorkOrders] = useState([])
  const [image, setImage] = useState('/media/avatars/blank.png')
  const navigate = useNavigate()
  const [createModal, setCreateModal] = useState(false)
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [forModal, setForModal] = useState(false)
  const [successBody, setSuccessBody] = useState<SuccessType>()
  const [inventory, setInventory] = useState<any>([])
  const [mfgOptions, setMfgOptions] = useState<any>([])
  const [nameOptions, setNameOptions] = useState<any>([])
  const inputFile = useRef<any>()
  const [draftImages, setDraftImages] = useState<any>([])
  const [draftNotes, setDraftNotes] = useState<any>([])
  const headerContext = useContext<any>(HeaderContext)
  const editorRef: any = useRef(null)
  const [needToOrder, setNeedToOrder] = useState([])
  const [forField, setForField] = useState([])
  const [previousRecords, setPreviousRecords] = useState([])
  const [forView, setForView] = useState<any[]>([])
  const [selectedValue2, setSelectedValue2] = useState([])
  const [selectedValue1, setSelectedValue1] = useState([])
  const [SelectedVendor, setSelecetedVendor] = useState('')
  const [SelectedShippingMethod, setSelectedShippingMethod] = useState('')
  const [showAssigneeError, setShowAssigneeError] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 500)
  const [searchItems, setSearchItems] = useState<any>([])
  const [fieldArrayData, setFieldArrayData] = useState([])
  const [getQuantity, setQuantity] = useState<number[]>([])
  const [getUnitCost, setUnitCost] = useState<number[]>([])
  const [subtotals, setSubtotals] = useState<number[]>([])
  const [item_counter, setItem_Counter] = useState<string[]>([])
  const [show_shipping_details, setshow_shipping_details] = useState(false)
  const [show_gt_detail, setshow_gt_detail] = useState(true)
  const [create_inventory_modal, set_create_inventory_modal] = useState(false)
  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newQuantity = parseFloat(e.target.value)
    setQuantity((prevQuantity) => {
      const updatedQuantity = [...prevQuantity]
      updatedQuantity[index] = newQuantity
      return updatedQuantity
    })
  }

  const handleUnitCostChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newUnitCost = parseFloat(e.target.value)
    setUnitCost((prevUnitCost) => {
      const updatedUnitCost = [...prevUnitCost]
      updatedUnitCost[index] = newUnitCost
      return updatedUnitCost
    })
  }

  useEffect(() => {
    const newSubtotals = getQuantity.map((q, index) => calculateSubtotal(q, getUnitCost[index]))
    setSubtotals(newSubtotals)
  }, [getQuantity, getUnitCost])

  const refData = useRef<any>(null)
  var subtotal: any = 0
  var overallQuantity: any = 0
  const allInventoryData = useCallback(async () => {
    setAnotherLoading(true)
    setLoading(true)
    await inventoryListForPO()
      .then((res) => {
        const inventoryItems = res?.data?.items
        setInventory(inventoryItems)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const calculateSubtotal = (quantity: any, unitCost: any) => {
    return parseFloat(quantity) * parseFloat(unitCost)
  }
  useEffect(() => {
    setAnotherLoading(true)
    let tempArr: {label: any; value: any}[] = []
    let tempArr2: {label: any; value: any}[] = []
    inventory.map((ele: any) => {
      return tempArr.push({
        label: ele?.sku,
        value: ele?.sku,
      })
    })
    inventory.map((ele: any) => {
      return tempArr2.push({
        label: ele?.name,
        value: ele?.name,
      })
    })
    setMfgOptions(tempArr)
    setNameOptions(tempArr2)
    setAnotherLoading(false)
  }, [inventory])

  const onButtonClick = () => {
    inputFile.current.click()
  }

  const getList = useCallback(async () => {
    setLoading(true)
    let shipping: any = []
    let vendor: any = []
    await getInventoryData()
      .then((res) => {
        let shippingTypes = res?.data?.shipping_types
        let vendors = res?.data?.vendors
        vendors.forEach((e: any) => {
          vendor.push({label: e.name, id: e.id, image: e?.image})
        })
        shippingTypes.forEach((e: any) => {
          shipping.push({label: e.name, id: e.id})
        })
        setDropdown(vendor)
        setOptions(shipping)
        setLoading(false)
      })
      .catch((e) => {
        if (e.response.status === 409) {
          setErrorBody(e.response.data)
          setLoading(false)
        }
      })
  }, [])

  const getWorkOrderCustomer = useCallback(() => {
    fetchCustomersWorkOrderUrl()
      .then((res) => {
        const requiredData = res?.data?.work_orders
        // const customerData = res?.data?.customer
        const newData = requiredData.map(({final_title, id}: any) => ({label: final_title, id: id}))
        setWorkOrders(newData)
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
      })
  }, [])

  const getNeedToOrder = useCallback(() => {
    setNewLoading(true)
    need_to_order_inventory_url()
      .then((res) => {
        const needOrder: any = []
        res?.data?.items.map((v: any) => (v?.available_stock < 0 ? needOrder.push(v) : null))
        setNeedToOrder(needOrder)
        setNewLoading(false)
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
        setNewLoading(false)
      })
  }, [])
  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Create New Purchase Order',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/inventory/purchase-order`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  const getSearchData = useCallback(async (searchTerm: any) => {
    setLoading(true)
    await inventorySearchListForPO(searchTerm)
      .then((res) => {
        const searchRes = res?.data?.items
        setSearchItems(searchRes || [])
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  const MaterialSearchItemsCol: any = useMemo(
    () => [
      {
        accessorFn: (row: any) => (
          <>
            <a href={row?.image_field || null} target='blank'>
              <img
                alt='customer logo'
                src={`${row?.image_field || '/media/custom/empty_inventory.svg'}`}
                onError={(evt: any) => {
                  evt.target.src = blank
                }}
                width='70px'
                height={'70px'}
                style={{objectFit: 'contain'}}
              />
            </a>
          </>
        ),
        accessorKey: 'inventory_image',
      },
      {
        accessorFn: (row: any) => row?.brand?.name,
        accessorKey: 'mfg',
      },
      {
        accessorFn: (row: any) => row.sku,
        accessorKey: 'mfg part #',
      },
      {
        accessorFn: (row: any) => row.name,
        accessorKey: 'item name',
      },
    ],
    []
  )

  // Search
  useEffect(() => {
    if (debouncedSearchTerm !== undefined && searchTerm !== undefined && searchTerm.length >= 3) {
      getSearchData(searchTerm)
    } else if (searchTerm.length === 0) {
      setSearchItems([])
    }
    return () => {}
  }, [getSearchData, searchTerm, debouncedSearchTerm])
  useEffect(() => {
    getNeedToOrder()
    getList()
    getWorkOrderCustomer()
    allInventoryData()
  }, [allInventoryData, getList, getNeedToOrder, getWorkOrderCustomer])

  const uploadProfileImg = (event: any, setFieldValue: any) => {
    const drafted_files = event?.target?.files
    setDraftImages(drafted_files)
  }

  const handleDraftedNotes = useCallback(() => {
    const notes = editorRef.current.getContent()
    setDraftNotes([...draftNotes, {notes}])
    setCreateModal(false)
  }, [editorRef, draftNotes])

  const onModalClose = useCallback(() => {
    setModalShow({show: false, label: ''})
    getList()
  }, [getList])

  const handleFormik = (
    selectedOption: any,
    index: any,
    setFieldValue: (arg0: string, arg1: string) => void
  ) => {
    const _result = inventory.find((ele: {sku: any}) => ele.sku === selectedOption.value)
    setFieldValue(`item_counter[${index}].item_sku`, _result?.sku || '')
    setFieldValue(`item_counter[${index}].item_name`, _result?.name || '')
    setFieldValue(`item_counter[${index}].item_manufacturer`, _result?.brand?.name || '')
    setFieldValue(`item_counter[${index}].item_available_stock`, _result?.available_stock || '')
    const idx = nameOptions.findIndex((option: any) => option.value === _result?.name)
    const newSelectedValue2: any = [...selectedValue2]
    newSelectedValue2[index] =
      idx >= 0 ? {value: nameOptions[idx].value, label: nameOptions[idx].label} : null
    setSelectedValue2(newSelectedValue2)
  }
  const handleFormik2 = (
    selectedOption: any,
    index: any,
    setFieldValue: (arg0: string, arg1: string) => void
  ) => {
    const _result2 = inventory.find((ele: {name: any}) => ele.name === selectedOption.value)
    setFieldValue(`item_counter[${index}].item_sku`, _result2?.sku || '')
    setFieldValue(`item_counter[${index}].item_name`, _result2?.name || '')
    setFieldValue(`item_counter[${index}].item_manufacturer`, _result2?.brand?.name || '')
    setFieldValue(`item_counter[${index}].item_available_stock`, _result2?.available_stock || '')
    const idxx = mfgOptions.findIndex((option: any) => option.value === _result2?.sku)
    const newSelectedValue1: any = [...selectedValue1]
    newSelectedValue1[index] =
      idxx >= 0 ? {value: mfgOptions[idxx].value, label: mfgOptions[idxx].label} : null
    setSelectedValue1(newSelectedValue1)
  }

  const handleCreateItem = (
    selectedOption: any,
    index: any,
    setFieldValue: (arg0: string, arg1: string) => void,
    createableData: any
  ) => {
    const optionString = createableData?.option?.label
    setLoading(true)
    let inventoryItems: InventoryModel = {
      name: '',
      image_field: undefined,
      maintain_stock: '',
      reorder_level: 0,
      item_cost: 0,
      retail_price: 0,
      available_stock: 0,
      qty_in_store: 0,
      order_link: '',
      category_id: '',
      item_status: '',
      brand_id: '',
      vendor_id: '',
      sku: optionString,
      in_transit_stock: '',
      reserved_stock: '',
      item_description: '',
      uploadImage: null,
      inventory_location: '',
    }
    createInventoryItem(inventoryItems)
      .then(({data}) => {
        setFieldValue(`item_counter[${index}].item_sku`, optionString)
        setLoading(false)
        allInventoryData()
        darkMessage('Item added successfully')
      })
      .catch((e) => {
        if (e.response.status === 409) {
          setErrorBody(e.response.data)
        }
        console.log(e)
        setLoading(false)
      })
  }

  const handleChange = (
    selectedOption: any,
    index: any,
    setFieldValue: (arg0: string, arg1: string) => void,
    type: any
  ) => {
    type.action === 'select-option' ? (
      handleFormik(selectedOption, index, setFieldValue)
    ) : type.action === 'create-option' ? (
      handleCreateItem(selectedOption, index, setFieldValue, type)
    ) : (
      <></>
    )
  }
  const handleChange2 = (
    selectedOption: any,
    index: any,
    setFieldValue: (arg0: string, arg1: string) => void,
    type: any
  ) => {
    type.action === 'select-option' ? (
      handleFormik2(selectedOption, index, setFieldValue)
    ) : type.action === 'create-option' ? (
      handleCreateItem(selectedOption, index, setFieldValue, type)
    ) : (
      <></>
    )
  }

  const handleClick = useCallback(
    (index: any) => {
      const updatedArray: any | string[] = [...fieldArrayData, index]
      setItem_Counter((prevItem_counter) => [...prevItem_counter, index?.id])
      setFieldArrayData(updatedArray)
    },
    [fieldArrayData]
  )

  const MaterialColumns: any = [
    {
      accessorFn: (row: any) => row.sku || 'N/A',
      header: 'MFG PART #', //uses the default width from defaultColumn prop
      accessorKey: 'need_mfg_part',
      size: 100,
    },
    {
      accessorFn: (row: any) => row.name || 'N/A',
      header: 'ITEM NAME', //uses the default width from defaultColumn prop
      accessorKey: 'need_item_name',
    },
    {
      accessorFn: (row: any) => row?.brand?.name || 'N/A',
      header: 'MFG', //uses the default width from defaultColumn prop
      accessorKey: 'need_mfg',
      size: 50,
    },
    {
      accessorFn: (row: any) => row?.vendor?.name || 'N/A',
      header: 'PREFERRED VENDOR', //uses the default width from defaultColumn prop
      accessorKey: 'need_preferred_vendor',
      size: 50,
    },
    {
      accessorFn: (row: any) => row.reserved_quantity || 0,
      header: 'QTY RESERVED', //uses the default width from defaultColumn prop
      accessorKey: 'need_qty_reserved',
      size: 50,
    },
    {
      accessorFn: (row: any) => row.old_stock || 0,
      header: 'QTY AVAILABLE', //uses the default width from defaultColumn prop
      accessorKey: 'need_qty_available',
      size: 50,
    },
    {
      accessorFn: (row: any) => Math.abs(row.available_stock) || 0,
      header: 'QTY TO ORDER', //uses the default width from defaultColumn prop
      accessorKey: 'need_qty_to_order',
      size: 50,
    },
    {
      accessorFn: (row: any) =>
        (row.final_title &&
          row.final_title.map((each_title: any) => {
            return (
              <>
                {each_title} <br />
              </>
            )
          })) ||
        'N/A',
      header: 'NEEDED FOR', //uses the default width from defaultColumn prop
      accessorKey: 'need_needed_for',
      size: 50,
    },
    {
      accessorFn: (row: any) => (
        <button
          type='button'
          className='btn btn-secondary px-2 py-1'
          onClick={() => handleClick(row)}
        >
          +
        </button>
      ),
      header: 'ADD TO PO', //uses the default width from defaultColumn prop
      accessorKey: 'need_add_to_po',
      size: 50,
    },
  ]
  const handleRemove = (_data: any, index: number) => {
    setFieldArrayData((prevData) => prevData.filter((_, i) => i !== index))
  }

  const columns: any = useMemo(
    () => [
      {
        name: 'ITEM LOGO',
        selector: (row: any) => (
          <a href={row?.image_field || null} target='blank'>
            <img
              alt='item'
              src={`${row?.image_field ? row?.image_field : '/media/custom/empty_inventory.svg'}`}
              width='70px'
              height={'70px'}
              style={{objectFit: 'contain'}}
            />
          </a>
        ),
      },
      {
        name: 'MFG',
        selector: (row: any) => row?.brand?.name,
      },
      {
        name: 'MFG. PART #',
        selector: (row: any) => row?.sku,
      },
      {
        name: 'ITEM NAME',
        selector: (row: any) => row?.name,
      },
      {
        name: 'AVAILABLE STOCK',
        selector: (row: any) => row?.available_stock,
      },
      {
        name: 'NEED TO ORDER',
        selector: (row: any) => 0,
      },
      {
        name: 'ORDER QTY',
        selector: (row: any, index: number) => (
          <div>
            <input
              required
              type='number'
              className='form-control'
              style={{padding: 4, borderBottom: '1px solid lightgrey'}}
              onChange={(e) => {
                handleQuantityChange(e, index)
                refData.current.setFieldValue(`item_counter.${index}.item_count`, e?.target?.value)
              }}
            />
          </div>
        ),
      },
      {
        name: 'ITEM COST',
        selector: (row: any, index: any) => (
          <div>
            <input
              required
              type='text'
              className='form-control'
              style={{padding: 4, borderBottom: '1px solid lightgrey'}}
              onChange={(e) => {
                handleUnitCostChange(e, index)
                refData.current.setFieldValue(`item_counter.${index}.unit_cost`, e?.target?.value)
              }}
            />
          </div>
        ),
      },
      {
        name: 'EXPECTED DELIVERY DATE',
        width: '180px',
        selector: (row: any, index: any) => (
          <div>
            <input
              type='date'
              className='form-control'
              style={{padding: 4, borderBottom: '1px solid lightgrey'}}
              onChange={(e) => {
                refData.current.setFieldValue(
                  `item_counter.${index}.expected_delivery`,
                  e?.target?.value
                )
              }}
            />
          </div>
        ),
      },
      {
        name: 'REFERENCE',
        selector: (row: any, index: any) => (
          <div>
            <input
              type='text'
              className='form-control'
              style={{padding: 4, borderBottom: '1px solid lightgrey'}}
              onChange={(e) => {
                refData.current.setFieldValue(`item_counter.${index}.ref`, e?.target?.value)
              }}
            />
          </div>
        ),
      },
      {
        name: 'SUBTOTAL',
        selector: (row: any, index: any) => <div>{NumberFormatter(subtotals[index])}</div>,
      },
      {
        name: 'Actions',
        selector: (row: any, index: any) => (
          <button className='btn btn-light' onClick={() => handleRemove(row, index)}>
            X
          </button>
        ),
      },
    ],
    [subtotals]
  )

  const handleNewVendor = (
    selectedOption: any,
    setFieldValue: (arg0: string, arg1: string) => void
  ) => {
    const optionString = selectedOption
    setLoading(true)
    let vendor: VendorInterface = {
      vendor_name: optionString,
      address_line_1: '',
      address_line_2: '',
      city: '',
      state: '',
      zip: '',
      website: '',
      terms: '',
      notes: '',
      image: '',
      po_count: 0,
    }
    createFullVendor(vendor)
      .then(({data}) => {
        setFieldValue('vendor_id', optionString)
        setSelecetedVendor(optionString)
        setLoading(false)
        getList()
        darkMessage('Vendor created successfully')
      })
      .catch((e) => {
        if (e.response.status === 409) {
          setErrorBody(e.response.data)
        }
        console.log(e)
        setLoading(false)
      })
  }
  const handleVendorCreate = (e: any, setFieldValue: any) => {
    handleNewVendor(e, setFieldValue)
  }

  const handleVendorChange = useCallback(
    (selectedOption: any, setFieldValue: (arg0: string, arg1: string) => void) => {
      setImage(selectedOption?.image || blank)
      setFieldValue('vendor_id', selectedOption.id)
      setSelecetedVendor(selectedOption?.label)
    },
    []
  )

  const handleNewShipping = (
    selectedOption: any,
    setFieldValue: (arg0: string, arg1: string) => void
  ) => {
    const optionString = selectedOption
    setLoading(true)
    createShippingType(optionString)
      .then(({data}) => {
        setFieldValue('shipping_method_id', optionString)
        setSelectedShippingMethod(optionString)
        setLoading(false)
        getList()
        darkMessage('Shipping Method created successfully')
      })
      .catch((e) => {
        if (e.response.status === 409) {
          setErrorBody(e.response.data)
        }
        console.log(e)
        setLoading(false)
      })
  }

  const handleShippingCreate = (e: any, setFieldValue: any) => {
    handleNewShipping(e, setFieldValue)
  }

  const handleShippingChange = useCallback(
    (selectedOption: any, setFieldValue: (arg0: string, arg1: string) => void) => {
      setSelectedShippingMethod(selectedOption?.label)
      setFieldValue('shipping_method_id', selectedOption?.id)
    },
    []
  )

  useEffect(() => {
    const filteredWorkorders: any = forField.map((each_field: any) =>
      workorders.filter((work_order: any) => {
        return work_order?.id === each_field?.assigned_work_order
      })
    )
    setForView(filteredWorkorders || [])
  }, [workorders, forField])

  const fetchRecords = useCallback((index: any) => {
    const json_Data = JSON.stringify(index)
    const api_result = fetchDummyAssignedItems(json_Data)
      .then((res) => {
        return res?.data?.result
      })
      .catch((e) => {
        console.error(e)
      })
    return api_result
  }, [])

  useEffect(() => {
    const old_records = forField.map((each_field: any) => each_field?.assigned_work_order)

    const fetchData = async () => {
      const result = await fetchRecords(old_records)
      setPreviousRecords(result || [])
    }
    fetchData()
  }, [fetchRecords, forField])

  const schema = Yup.object().shape({
    item_counter: Yup.array().of(
      Yup.object().shape({
        // unit_cost: Yup.number().min(0.0, 'Unit cost must be a positive number'),
        // item_count: Yup.number().min(1, 'Quantity must be at least 1'),
      })
    ),
    vendor_id: Yup.string().required('Vendor is required'),
    date_ordered: Yup.date().required('Order Date is required'),
  })

  useEffect(() => {
    setShowAssigneeError(false)
  }, [forView])

  const handleFieldArrayItems = useCallback(
    (index_data: any) => {
      const dataArrayCopy: any = [...fieldArrayData]
      dataArrayCopy.push(index_data)
      setItem_Counter((prevItem_counter: any) => [...prevItem_counter, index_data?.id])
      setFieldArrayData(dataArrayCopy)
      setSearchTerm('')
    },
    [fieldArrayData]
  )

  return (
    <>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        label={modal?.label}
        show={modal?.show}
        onHide={() => setModalShow(false)}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <InventoryManagement
          onModalClose={onModalClose}
          label={modal?.label}
          show={modal?.show}
          customerData={modal?.data}
          onHide={() => setModalShow(false)}
        />
      </Modal>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={create_inventory_modal}
        onHide={() => set_create_inventory_modal(false)}
        onModalClose={() => set_create_inventory_modal(false)}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <InventoryForm
          checkIsInventoryModal={true}
          closeInventoryModal={() => {
            set_create_inventory_modal(false)
          }}
        />
      </Modal>
      <Formik
        innerRef={refData}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values, {resetForm}) => {
          const transformedArr = values?.item_counter.map((obj: any, index) => ({
            id: item_counter[index],
            ...obj,
          }))

          if (forView.length === 0) {
            setShowAssigneeError(true)
            return
          } else {
            setShowAssigneeError(false)
          }
          let items_info_data: any = []
          overallQuantity = 0
          setLoading(true)
          values?.item_counter.map((item: any, index: any) => {
            return (overallQuantity += parseFloat(item?.item_count))
          })
          transformedArr.map((item, index) => {
            return items_info_data.push({
              item_id: item?.id,
              ref: item?.ref || '',
              quantity: item?.item_count || 0,
              unit_cost: item?.unit_cost || 0,
              expected_delivery: item?.expected_delivery || 0,
            })
          })

          let purchase_order: PurchaseOrderModel = {
            items_info: JSON.stringify(items_info_data),
            vendor_id: values.vendor_id,
            for_assignee: JSON.stringify(forField),
            date_ordered: values.date_ordered,
            shipping_method_id: values.shipping_method_id,
            expected_delivery: values.expected_delivery,
            tracking_number: values.tracking_number,
            order_subtotal: subtotal,
            shipping_cost: values.shipping_cost,
            tax: values.tax,
            total_cost:
              (parseFloat(subtotal) || 0) +
              (parseFloat(values?.shipping_cost) || 0) +
              (parseFloat(values?.tax) || 0),
            order_status: 'IN_TRANSIT',
            overall_quantity: overallQuantity,
            received_total: '',
            attached_files: draftImages,
            attached_notes: JSON.stringify(draftNotes),
            order_link: '',
            parent_ref: '',
            vendor_ordernumber: values?.vendor_ordernumber,
          }
          createPurchaseOrder(purchase_order)
            .then(({data}) => {
              setSuccessBody(data as any)
              setLoading(false)
              resetForm()
              darkMessage('Purchase order created successfully')
              navigate(`/${prefixCompany}/inventory/purchase-order`)
            })
            .catch((e) => {
              if (e.response.status === 409) {
                setErrorBody(e.response.data)
                setLoading(false)
              }
              setLoading(false)
            })
        }}
        render={({values, touched, errors, isValid, setFieldValue, getFieldProps}: any) => {
          subtotal = 0
          values?.item_counter.map((item: any, index: any) => {
            return (subtotal =
              subtotal + parseFloat(item?.item_count) * parseFloat(item?.unit_cost))
          })
          const formattedSubtotal = subtotal
          return (
            <Form onKeyDown={onKeyDown}>
              {errorBody && <ErrorAlert error_response={errorBody} />}
              {successBody && <SuccessAlert success_response={successBody} />}
              <div className='container'>
                <div className='row'>
                  <div className='col-xl-9 col-lg-12 col-md-12'>
                    {newLoading && <LinearProgress />}
                    <div className='mb-5 mb-xl-10'>
                      <MaterialReactTable
                        columns={MaterialColumns}
                        data={needToOrder}
                        enableSorting={false}
                        enableHiding={false}
                        enableFilters={false}
                        muiTableHeadCellProps={{
                          sx: {
                            fontWeight: 'bold',
                            fontSize: '14px',
                          },
                        }}
                        muiTableBodyCellProps={{
                          style: {fontSize: '13px'},
                        }}
                        enableStickyHeader
                        enableStickyFooter
                        manualFiltering
                        enableGlobalFilter={true}
                        enableColumnActions={false}
                        renderTopToolbarCustomActions={({table}) => (
                          <div className='card-title m-0 d-flex d-inline align-items-center'>
                            <h4 className='fw-bolder m-5 w-115px'>Need To Order</h4>
                          </div>
                        )}
                        enableDensityToggle={false}
                        enableFullScreenToggle={false}
                        enablePagination={false}
                        enableColumnFilters={false}
                        enableBottomToolbar={false}
                      />
                    </div>
                    <div className='card mb-5 mb-xl-10'>
                      <div
                        className='card-header border-0 cursor-pointer'
                        role='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_account_profile_details'
                        aria-expanded='true'
                        aria-controls='kt_account_profile_details'
                      >
                        <div className='d-flex p-lg-5 align-items-center m-0 items-order-wrapper'>
                          <h3 className='fw-bolder m-3'>Items to Order</h3>
                          <div className='item-heading-row'>
                            <div className='d-flex align-items-center position-relative my-1'>
                              <KTSVG
                                path='/media/icons/duotune/general/gen021.svg'
                                className='svg-icon-4 position-absolute ms-6'
                              />
                              <input
                                style={{fontSize: '14px', padding: '7px 10px'}}
                                type='text'
                                data-kt-user-table-filter='search'
                                className='form-control form-control-solid w-300px ps-14 search-wrap'
                                placeholder='Search Existing Inventory Items'
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                              />
                            </div>
                            <div className='d-flex align-items-center position-relative mx-2 my-1'>
                              <button
                                className='btn btn-secondary'
                                type='button'
                                onClick={() => set_create_inventory_modal(true)}
                              >
                                + Create New Inventory Item
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {searchItems && searchItems.length > 0 && (
                        <div
                          className='pop_up_table'
                          style={{
                            position: 'absolute',
                            zIndex: '1',
                            marginTop: '60px',
                            marginLeft: '165px',
                            border: '1px solid black',
                            maxHeight: '400px',
                            overflow: 'scroll',
                          }}
                        >
                          <SimpleDataTable
                            MaterialColumns={MaterialSearchItemsCol}
                            MaterialColumnsApiData={searchItems}
                            stopRedirection={true}
                            handleClick={(cb_data: any) => {
                              handleFieldArrayItems(cb_data)
                            }}
                          />
                        </div>
                      )}
                      <div id='kt_account_profile_details' className='collapse show '>
                        <div className='border-top bottom_table'>
                          <DataTable
                            pointerOnHover
                            highlightOnHover
                            columns={columns}
                            data={fieldArrayData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-xl-3 col-lg-12 col-md-6'>
                    <div className='position-relative card mb-5 mb-xl-10'>
                      <div id='kt_account_profile_details' className='collapse show'>
                        <div className='card-body border-top p-5'>
                          <form>
                            <div className='row'>
                              <div className='col-md-12'>
                                <div className='row '>
                                  <div className='col-md-4 pe-0 d-flex justify-content-center'>
                                    <a href={image} target='blank'>
                                      <img
                                        alt='vendor here'
                                        src={image}
                                        onError={(evt: any) => {
                                          evt.target.src = blank
                                        }}
                                        style={{
                                          objectFit: 'contain',
                                          borderRadius: '10px',
                                          maxWidth: '80px',
                                          width: '100%',
                                          height: '90px',
                                        }}
                                      />
                                    </a>
                                  </div>
                                  <div className='col-md-8 mb-3'>
                                    <div className='row gap-2'>
                                      <div className='col-md-12'>
                                        <CreatableReactSelect
                                          isSearchable
                                          className='w-100 '
                                          isLoading={anotherloading}
                                          options={dropdown}
                                          onChange={(e: any) => {
                                            handleVendorChange(e, setFieldValue)
                                          }}
                                          onCreateOption={(e) => {
                                            handleVendorCreate(e, setFieldValue)
                                          }}
                                          value={{
                                            value: SelectedVendor,
                                            label: SelectedVendor,
                                          }}
                                          placeholder='Vendor'
                                        />
                                        {errors.vendor_id && touched.vendor_id && (
                                          <div className='text-danger'>{errors.vendor_id}</div>
                                        )}
                                      </div>
                                      <div className='col-md-12'>
                                        <Field
                                          as={TextField}
                                          variant='outlined'
                                          label='Vendor Order #'
                                          name='vendor_ordernumber'
                                          style={{marginTop: '7px', width: '100%', zIndex: '0'}}
                                          className={`form-control ${
                                            touched.vendor_ordernumber && errors.vendor_ordernumber
                                              ? 'is-invalid'
                                              : ''
                                          }`}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='row '>
                                  <div className='col-md-4 pe-0 d-flex align-items-center justify-content-center'>
                                    <label
                                      className='d-block fw-bold'
                                      htmlFor='date_ordered'
                                      style={{
                                        fontSize: '16px',
                                      }}
                                    >
                                      Order Date
                                    </label>
                                  </div>
                                  <div className='col-md-8'>
                                    <Field
                                      placeholder='Order Date'
                                      type='date'
                                      name='date_ordered'
                                      className={`mt-2 form-control  ${
                                        touched.date_ordered && errors.date_ordered
                                          ? 'is-invalid'
                                          : ''
                                      }`}
                                      defaultValue={initialValues.date_ordered}
                                    />
                                  </div>
                                </div>
                                <div className='row mt-5'>
                                  <div className='col-md-4 pe-0 d-flex justify-content-center'>
                                    <span
                                      className='d-block fw-bold'
                                      style={{
                                        fontSize: '16px',
                                      }}
                                    >
                                      For
                                      <i
                                        className='bi bi-pencil-square mx-2 cursor-pointer'
                                        style={{color: 'black'}}
                                        onClick={() => setForModal(true)}
                                      ></i>
                                    </span>
                                  </div>
                                  <div className='col-md-8'>
                                    {forView.length > 0 ? (
                                      forView.map((item: any, key: number) => {
                                        return item.map((eachItem: any, subkey: number) => {
                                          return (
                                            <div
                                              style={{fontSize: '15px'}}
                                              key={subkey}
                                              className='d-block'
                                            >
                                              <strong>{eachItem?.label}</strong> is assigned
                                            </div>
                                          )
                                        })
                                      })
                                    ) : (
                                      <h5>There is no Assignee </h5>
                                    )}
                                    {showAssigneeError === true && (
                                      <p className='text-danger'>
                                        Please select at least one assignee.
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className='position-relative card mb-5 mb-xl-10'>
                      <div id='kt_account_profile_details' className='collapse show'>
                        <div className='card-body border-top p-5'>
                          <form>
                            <div className='row gap-3'>
                              <div className='d-flex justify-content-between border-bottom pb-5'>
                                <h3 className='mb-0'>Shipping Details</h3>
                                <span
                                  onClick={() => {
                                    setshow_shipping_details(
                                      (prev_shipping_details) => !prev_shipping_details
                                    )
                                  }}
                                  className='text-end'
                                >
                                  {show_shipping_details ? (
                                    <KTSVG path={'/media/custom/up.svg'} />
                                  ) : (
                                    <KTSVG path={'/media/custom/down.svg'} />
                                  )}
                                </span>
                              </div>
                              {show_shipping_details && (
                                <div>
                                  <div className='col-md-12 position-relative'>
                                    <CreatableReactSelect
                                      isSearchable
                                      className='w-100'
                                      isLoading={anotherloading}
                                      options={options}
                                      onChange={(e: any) => {
                                        handleShippingChange(e, setFieldValue)
                                      }}
                                      onCreateOption={(e) => {
                                        handleShippingCreate(e, setFieldValue)
                                      }}
                                      value={{
                                        value: SelectedShippingMethod,
                                        label: SelectedShippingMethod,
                                      }}
                                      placeholder='Shipping Method'
                                    />
                                  </div>
                                  <div className='col-md-12'>
                                    <Field
                                      as={TextField}
                                      variant='outlined'
                                      label='Tracking Number'
                                      style={{marginTop: '7px', width: '100%', zIndex: '0'}}
                                      className='form-control'
                                      name='tracking_number'
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className='position-relative card mb-5 mb-xl-10'>
                      <div id='kt_account_profile_details' className='collapse show'>
                        <div className='card-body border-top p-5'>
                          <form>
                            <div className='purchase-order-total'>
                              <div className='d-flex justify-content-between border-bottom pb-5'>
                                <h3 className='mb-1'>Totals</h3>
                                <span
                                  onClick={() => {
                                    setshow_gt_detail((prev_gt_detail) => !prev_gt_detail)
                                  }}
                                  className='text-end'
                                >
                                  {show_gt_detail ? (
                                    <KTSVG path={'/media/custom/up.svg'} />
                                  ) : (
                                    <KTSVG path={'/media/custom/down.svg'} />
                                  )}
                                </span>
                              </div>
                              {show_gt_detail && (
                                <div className='row total-order-fileds'>
                                  <div className='col-md-5 p-lg-3 d-flex justify-content-end align-items-center'>
                                    <label
                                      className='d-block fw-bold text-muted'
                                      htmlFor='order_subtotal'
                                    >
                                      Order Subtotal
                                    </label>
                                  </div>
                                  <div className='col-md-7 p-lg-3 d-flex justify-content-center align-items-center'>
                                    <span className='text-muted fw-bold'>
                                      {NumberFormatter(formattedSubtotal)}
                                    </span>
                                  </div>
                                  <div className='col-md-5 p-lg-3 d-flex justify-content-end align-items-center'>
                                    <label
                                      className='d-block fw-bold text-muted'
                                      htmlFor='shipping_cost'
                                    >
                                      Shipping Cost
                                    </label>
                                  </div>
                                  <div className='col-md-7 p-lg-3 d-flex justify-content-center align-items-center '>
                                    <Field
                                      as={TextField}
                                      variant='outlined'
                                      type='number'
                                      className='custom-class_for_po shipping-cost-field'
                                      min={0}
                                      name='shipping_cost'
                                    />
                                  </div>
                                  <div className='col-md-5 p-lg-3  d-flex justify-content-end align-items-center'>
                                    <label className='d-block fw-bold text-muted'>Tax</label>
                                  </div>
                                  <div className='col-md-7 p-lg-3 m-top-10 d-flex justify-content-center align-items-center'>
                                    <Field
                                      as={TextField}
                                      variant='outlined'
                                      type='number'
                                      name='tax'
                                      min={0}
                                      className='custom-class_for_po'
                                    />
                                  </div>
                                  <div className='col-md-5 p-lg-3 border-top border-dark d-flex justify-content-end align-items-center mt-3'>
                                    <label
                                      className='d-block fw-bold text-dark'
                                      htmlFor='total_cost'
                                    >
                                      Grand Total
                                    </label>
                                  </div>
                                  <div className='col-md-7 p-lg-3 border-top border-dark d-flex justify-content-center align-items-center mt-3'>
                                    <span className='text-dark fw-bold'>
                                      {`$${
                                        (
                                          (Number(formattedSubtotal) || 0) +
                                          (Number(values?.shipping_cost) || 0) +
                                          (Number(values?.tax) || 0)
                                        )
                                          .toFixed(2)
                                          .toLocaleString() || ''
                                      }`}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div className='upload-block'>
                      <div className='upload-header mb-3  border-bottom pb-5'>
                        <div>
                          <h3>Attached files</h3>
                        </div>
                        <button
                          className='add-upload border-0'
                          type='button'
                          onClick={onButtonClick}
                        >
                          <img src='/media/logos/upload.png' alt='upload files'></img>
                          <h6>Upload Files</h6>
                        </button>
                        <input
                          ref={inputFile}
                          type='file'
                          multiple
                          onChange={(e) => uploadProfileImg(e, setFieldValue)}
                          name='file'
                          id=''
                          style={{display: 'none'}}
                        />
                      </div>
                      <div className='text-left mt-3'>
                        {Object.keys(draftImages).map((e: any) => (
                          <p style={{color: 'rgb(0, 158, 247)'}} className='fs-5 px-2'>
                            {draftImages[e]?.name}
                          </p>
                        ))}
                      </div>
                    </div>
                    <div className='notes-block'>
                      <div className='notes-header  border-bottom pb-5'>
                        <h3>Notes</h3>
                        <button
                          className='add-note border-0'
                          type='button'
                          onClick={() => {
                            setCreateModal(true)
                          }}
                        >
                          <img src='/media/logos/notes.png' alt='add notes'></img>
                          <h6>Add Note</h6>
                        </button>
                      </div>
                      {draftNotes.map(({notes}: any) => {
                        return <p dangerouslySetInnerHTML={{__html: notes}} />
                      })}
                    </div>

                    <div>
                      <button
                        type='submit'
                        style={{width: '85%', margin: '0 auto'}}
                        className='btn btn-primary mt-5 d-flex justify-content-center'
                        disabled={loading || !isValid}
                      >
                        {!loading && 'Submit PO'}
                        {loading && (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )
        }}
      />
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          setCreateModal(false)
        }}
        show={createModal}
        className='fade'
        onHide={() => setCreateModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='xl'
      >
        <Modal.Header>
          <Modal.Title>
            <h1>Create New Notes</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <Editor
            apiKey='0eft9hxkqsjsqk6k7ktmr2souml1bokfgct065bjayhaadex'
            onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex flex-row-reverse mx-2 m-3'>
            <span className='mx-2'>
              <button
                className='btn btn-primary'
                type='button'
                onClick={() => {
                  handleDraftedNotes()
                }}
              >
                Add Notes
              </button>
            </span>
            <span className='mx-1'>
              <button
                onClick={() => {
                  setCreateModal(false)
                }}
                type='button'
                className='btn btn-dark'
              >
                Cancel
              </button>
            </span>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          setForModal(false)
        }}
        show={forModal}
        className='fade'
        onHide={() => setForModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='xl'
      >
        <Modal.Header>
          <Modal.Title>
            <h1>Assign</h1>
          </Modal.Title>
        </Modal.Header>
        <PurchaseOrderFor
          closeModal={() => setForModal(false)}
          getList={(e: any) => setForField(e || [])}
          editRecord={previousRecords}
        />
      </Modal>
    </>
  )
}
export {PurchaseOrderForm}
