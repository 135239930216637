/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect, useState} from 'react'
import {
  lifetimeList,
  getCustomerLifespan,
  darkMessage,
  currnetCompanyName,
} from '../../modules/auth/core/_requests'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {Modal} from 'react-bootstrap'

const LASTWISH = [
  {
    value: '30',
  },
  {
    value: '60',
  },
  {
    value: '90',
  },
  {
    value: '120',
  },
  {
    value: '150',
  },
]

function Lifetime({modalHandler, handleCloseModal}: any) {
  const [loading, setLoading] = useState(false)
  const [selectedClient, setSelectedClient] = useState()
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const prefixCompany = currnetCompanyName(currentUser?.company)

  const getList = useCallback(async (lifetime: any = selectedClient) => {
    setLoading(true)
    await lifetimeList(lifetime)
      .then((res) => {
        setSelectedClient(res?.data?.data?.customer_life)
        navigate(`/${prefixCompany}/customers`)
        darkMessage('Data Updated Successfully')
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [])

  const getData = useCallback(async () => {
    setLoading(true)
    await getCustomerLifespan()
      .then((res) => {
        setSelectedClient(res?.data?.data?.customer_life)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
      })
  }, [])
  useEffect(() => {
    getData()
  }, [])

  const handleSave = async () => {
    setLoading(true)
    await getList(selectedClient)
      .then((res: any) => {
        const message = res?.data?.message
        setLoading(false)
        handleCloseModal()
        darkMessage(message)
      })
      .catch((e) => {
        setLoading(false)
      })
  }

  const handleActive = useCallback((event: any) => {
    setSelectedClient(event.target.value)
  }, [])

  return (
    <>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          handleCloseModal()
        }}
        show={modalHandler}
        onHide={() => handleCloseModal()}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='lg'
      >
        <Modal.Header>
          <Modal.Title>
            <h2>Customer Active/Inactive Settings</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='card'>
            <div className='row mb-6'>
              <label className='col-lg-12 col-form-label required fw-bold fs-6'>
                Select the number of days you want to pass before Blu Portal automatically marks
                the customer as inactive if there is no new activity for the customer
              </label>
              <div className='col-md-12'>
                <select
                  className='form-control form-control-lg form-control-solid w-100'
                  value={selectedClient}
                  onChange={handleActive}
                >
                  <option value={selectedClient} selected hidden disabled>
                    {selectedClient}
                  </option>
                  {LASTWISH.map((choices: any) => {
                    return <option value={choices?.value}>{choices?.value}</option>
                  })}
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type='submit'
              className='btn btn-dark btn-sm mx-3'
              onClick={() => handleCloseModal()}
            >
              Discard
            </button>
            <button
              type='submit'
              className='btn btn-primary btn-sm'
              disabled={loading}
              onClick={handleSave}
            >
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Lifetime
