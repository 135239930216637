import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  API_URL,
  companyDetails,
  createPurchaseOrderNotes,
  currnetCompanyName,
  darkErrorMessage,
  darkMessage,
  deletePdfTempFiles,
  deletePO,
  deletePurchaseOrderFiles,
  deletePurchaseOrderForField,
  deletePurchaseOrderNotes,
  fetchPurchaseOrderFiles,
  fetchPurchaseOrderNotes,
  purchaseOrderItemData,
  savePdfTempFiles,
  updatePO,
  updatePurchaseOrderFiles,
  updatePurchaseOrderNotes,
  uploadPurchaseOrderFiles,
  updatePOReceived,
} from '../../modules/auth/core/_requests'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {KTSVG} from '../../../_metronic/helpers'
import SimpleDataTable from './SimpleDataTable'
import DataTable from 'react-data-table-component'
import {Button, Modal, ModalBody} from 'react-bootstrap'
import * as Yup from 'yup'
import blank from '../../../_metronic/layout/components/header/blank.png'
import {useFormik} from 'formik'
import moment from 'moment'
import {MaterialDataTable} from './MatrialDataTable'
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from '@mui/material'
import {Editor} from '@tinymce/tinymce-react'
import {
  HAS_PO_Notes_Edit,
  HAS_PurchaseOrderDeletePO,
  HAS_PurchaseOrderUpdatePO,
  HAS_PurchaseOrderViewPO,
  // HAS_PurchaseOrderViewPO
} from './PermissionFunction'
import {PurchaseOrderFor} from './PurchaseOrderFor'
import DeleteModal from './DeleteModal'
import jsPDF from 'jspdf'
import {CommaNumberFormatter, NumberFormatter, useLocalStorage} from './GlobalComponents'
import EditIcon from '@mui/icons-material/Edit'
import MaterialReactTable, {
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_TableInstance,
  MRT_ToggleDensePaddingButton,
} from 'material-react-table'
interface Props {
  jio: any
  jid: undefined
  pdata: boolean
  updateTable: any
  recretM: (ev: boolean) => {}
}

const PurchaseOrderView: React.FC<Props> = ({recretM, jio, jid, pdata, updateTable}) => {
  const {currentUser} = useAuth()
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  })
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const {id}: any = useParams()
  const headerContext = useContext<any>(HeaderContext)
  const [tableStorage, setTableStorage] = useLocalStorage('purchaseViewVisibilty', '')
  const [data, setData] = useState<any>([])
  const [companydata, setCompanydata] = useState<any>([])
  const [for_assignee, setFor_assignee] = useState<any>([])
  const [header, setHeader] = useState<any>('')
  const [items, setItems] = useState<any>([])
  const [modal, setModal] = useState(false)
  const [receiveModal, setReceivedModal] = useState(false)
  const [deleteFileModal, setDeleteFileModal] = useState({
    show: false,
    data: null,
  })
  const [updateFileModal, setUpdateFileModal] = useState({
    show: false,
    data: null,
  })
  const [forDeleteModal, setForDeleteModal] = useState({
    show: false,
    index: null,
  })
  const [dropdownFunction, setDropdownFunction] = useState<Boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [iconShow, setIconShow] = useState(false)
  const [forIconShow, setForIconShow] = useState(false)
  const inputFile = useRef<any>()
  const [newModal, setNewModal] = useState(false)
  const [fileLabel, setFileLabel] = useState('')
  const [loading, setLoading] = useState(false)
  const [createModal, setCreateModal] = useState(false)
  const [uploadedFiles, setUploadedFiles] = useState([])
  const [forModal, setForModal] = useState(false)

  const [deleteNotesModal, setDeleteNotesModal] = useState({
    modal: false,
    data: null,
  })
  const [updateNotesModal, setUpdateNotesModal] = useState<any>({
    modal: false,
    data: null,
  })
  const [fetchPONotes, setFetchPONotes] = useState([])
  const location = useLocation()
  const [totalRows, seTotalRows] = useState(0)
  const editorRef: any = useRef(null)
  const [route, setRoute] = useState(false)
  const [lineItemsCount, setLineItemsCount] = useState(0)
  const [hideDirector, setHideDirector] = React.useState(true)
  const [hoverKey, setHoverKey] = useState(false)
  const [pdfLoading, setPdfLoading] = useState(false)
  const [forHoverKey, setForHoverKey] = useState<any>(0)
  const [recieveEditModal, setReceivedEditModal] = useState(false)
  const tableInstanceRef = useRef<MRT_TableInstance<any>>(null)
  const search_routes = [`/${prefixCompany}/inventory/purchase-order/create`]
  const initialValues = {}

  const SubtotalFormatter = (row_idx: any) => {
    const Subtotal = row_idx?.item_cost * row_idx?.quantity
    return NumberFormatter(Subtotal)
  }

  const [state, setState] = useState<any>({
    photo: '',
  })
  const {photo} = state

  const handleDeleteFileModal = useCallback(() => {
    setDeleteFileModal({
      show: false,
      data: null,
    })
  }, [])

  const handleUpdateFileModal = useCallback(() => {
    setUpdateFileModal({
      show: false,
      data: null,
    })
  }, [])
  const handleNotesDeleteModal = useCallback(() => {
    setDeleteNotesModal({
      modal: false,
      data: null,
    })
  }, [])

  const handleNotesUpdateModal = useCallback(() => {
    setUpdateNotesModal({
      modal: false,
      data: null,
    })
  }, [])

  useLayoutEffect(() => {
    if (jio === 1) {
      setHideDirector(false)
    }
    if (pdata) {
      setHeader('RECEIVED TODAY')
    } else {
      setHeader('Quantity Returned')
    }
  }, [hideDirector, pdata])
  const navigate = useNavigate()

  const fileuploadschema = Yup.object().shape({
    selected_files: Yup.mixed().required(),
  })

  const filesUploadFormik = useFormik({
    initialValues,
    validationSchema: fileuploadschema,
    onSubmit: async (values: any, {setStatus, setSubmitting}) => {
      try {
        let pic = values.selected_files
        async function getData(file: any, id: any) {
          setLoading(true)
          await uploadPurchaseOrderFiles(id, file)
            .then((res) => {
              filesUploadFormik.resetForm()
              setTimeout(() => {
                darkMessage('File Uploaded Successfully')
                setLoading(false)
                getPOFiles()
              }, 3000)
            })
            .catch((e) => {
              setLoading(false)
              console.error(e)
            })
          setNewModal(false)
        }
        getData(pic, id)
      } catch (error: any) {
        console.error(error)
        setNewModal(false)
      }
    },
  })

  useEffect(() => {
    filesUploadFormik.values?.selected_files && filesUploadFormik.values?.selected_files?.length
      ? setNewModal(true)
      : setNewModal(false)
  }, [filesUploadFormik])
  const cbFunction = useCallback((columnScope: {}) => {
    setTableStorage(columnScope)
  }, [])

  useEffect(() => {
    getList(pagination.pageIndex, '', pagination.pageSize)
  }, [pagination])

  const PaginationCallback = useCallback((getPagination: any) => {
    getList(getPagination?.pageIndex, '', getPagination?.pageSize)
  }, [])

  const formik = useFormik({
    initialValues,
    onSubmit: (values: any) => {
      const update_data = items.map((item: any) => {
        const currentValue = values[item.id]
        if (currentValue !== undefined && currentValue !== '') {
          return {
            ...item,
            custom_received: currentValue,
          }
        }
        return {
          ...item,
          custom_received: 0,
        }
      })

      updatePO(JSON.stringify(update_data))
        .then((res) => {
          const allStatus = res?.data?.all_status
          allStatus.map((eachStatus: any) => {
            return darkMessage(eachStatus)
          })
          getList()
          getPONotes()
          updateTable()

          items.map((item: any) => {
            return {
              ...item,
              custom_return: 0,
              custom_received: 0,
            }
          })
        })
        .catch((e: any) => {
          if (e.response.status === 409) {
            darkErrorMessage(e?.response?.data?.error_list[0])
          } else {
            darkErrorMessage('Error')
          }
          console.error(e)
        })
    },
  })

  const receivedFormik = useFormik({
    initialValues,
    onSubmit: (values: any) => {
      const update_data = items.map((item: any) => {
        const currentValue = values[item.id]
        if (currentValue !== undefined && currentValue !== '') {
          return {
            ...item,
            received: currentValue,
          }
        }
        return {
          ...item,
          received: item.received,
        }
      })
      updatePOReceived(JSON.stringify(update_data))
        .then((res) => {
          const allStatus = res?.data?.all_status
          allStatus.map((eachStatus: any) => {
            return darkMessage(eachStatus)
          })
          getList()
          getPONotes()
          updateTable()

          items.map((item: any) => {
            return {
              ...item,
              custom_return: 0,
              custom_received: 0,
            }
          })
        })
        .catch((e: any) => {
          if (e.response.status === 409) {
            darkErrorMessage(e?.response?.data?.error_list[0])
          } else {
            darkErrorMessage('Error')
          }
          console.error(e)
        })
    },
  })

  const onClickEditReceived = () => {
    setReceivedEditModal(true)
  }

  const handleSub = (e: any) => {
    e.preventDefault()
    formik.submitForm()
    updateTable()
    window.location.reload()
    recretM(false)
  }
  const handleRecivedSub = (e: any) => {
    e.preventDefault()
    receivedFormik.submitForm()
    updateTable()
    recretM(false)
  }
  let len = 0
  jio !== 0 ? (len = 12) : (len = 9)
  const columns: any = useMemo(
    () => [
      {
        name: 'ITEM LOGO',
        selector: (row: any) => (
          <a href={row?.item_sku?.image_field || null} target='blank'>
            <img
              alt='item'
              src={`${
                row?.item_sku?.image_field
                  ? row?.item_sku?.image_field
                  : '/media/custom/empty_inventory.svg'
              }`}
              width='70px'
              height={'70px'}
              style={{objectFit: 'contain', maxWidth: '100%'}}
            />
          </a>
        ),
      },
      {
        name: 'MFG',
        selector: (row: any) => row?.item_sku?.brand?.name || row?.brand,
      },
      {
        name: 'MFG. PART #',
        selector: (row: any) => row?.item_sku?.sku || row?.item_sku,
      },
      {
        name: 'ITEM NAME',
        selector: (row: any) => (
          <div className='w-auto' title={row?.item_name}>
            {row?.item_name}
          </div>
        ),
      },
      {
        name: 'ORDERED',
        selector: (row: any) => CommaNumberFormatter(row?.quantity),
      },
      {
        name: recieveEditModal ? (
          <span>RECEIVED</span>
        ) : (
          <span className='d-flex'>
            ALREADY RECEIVED
            <span className='mx-2'>
              <EditIcon onClick={onClickEditReceived} />
            </span>
          </span>
        ),
        selector: (row: any) =>
          !recieveEditModal ? (
            CommaNumberFormatter(row?.received)
          ) : (
            <div>
              <form onSubmit={handleRecivedSub}>
                <input
                  required
                  type='number'
                  className='form-control'
                  style={{padding: 4, borderBottom: '1px solid lightgrey'}}
                  defaultValue={row.received}
                  max={pdata ? row.quantity : row.received}
                  {...receivedFormik.getFieldProps(row.id)}
                />
              </form>
            </div>
          ),
      },
      {
        name: header,
        omit: !recieveEditModal ? hideDirector : true,
        selector: (row: any) => (
          <div>
            <form onSubmit={handleSub}>
              <input
                required
                type='number'
                className='form-control'
                style={{padding: 4, borderBottom: '1px solid lightgrey'}}
                defaultValue={0}
                max={pdata ? row.quantity : row.received}
                {...formik.getFieldProps(row.id)}
              />
            </form>
          </div>
        ),
      },
    ],
    [hideDirector, recieveEditModal]
  )

  const MaterialColumns: any = useMemo(
    () => [
      {
        header: 'ITEM LOGO',
        accessorKey: 'po_item_logo',
        size: 50, //decrease the width of this column
        accessorFn: (row: any) => (
          <a href={row?.item_sku?.image_field || null} target='blank'>
            <img
              alt='item'
              src={`${
                row?.item_sku?.image_field
                  ? row?.item_sku?.image_field
                  : '/media/custom/empty_inventory.svg'
              }`}
              width='70px'
              height={'70px'}
              style={{objectFit: 'contain', maxWidth: '100%'}}
            />
          </a>
        ),
      },
      {
        header: 'MFG',
        size: 100, //decrease the width of this column
        accessorKey: 'po_mfg',
        accessorFn: (row: any) => row?.item_sku?.brand?.name || row?.brand,
      },
      {
        header: 'MFG. PART #',
        size: 150, //decrease the width of this column
        accessorKey: 'po_mfg_part_#',
        accessorFn: (row: any) => row?.item_sku?.sku || row?.item_sku,
      },
      {
        header: 'ITEM NAME',
        accessorKey: 'po_item_name',
        accessorFn: (row: any) => (
          <div className='w-auto' title={row?.item_name}>
            {row?.item_name}
          </div>
        ),
      },
      {
        header: 'UNIT COST',
        size: 100, //decrease the width of this column
        accessorKey: 'po_unit_cost',
        accessorFn: (row: any) => NumberFormatter(row?.item_cost),
      },
      {
        header: (
          <span style={{cursor: 'pointer'}} title='Estimated Time Of Arrival'>
            ETA
          </span>
        ),
        size: 100, //decrease the width of this column
        accessorKey: 'po_expected_delivery',
        accessorFn: (row: any) =>
          row?.expected_delivery ? moment(row?.expected_delivery).format('MMM. Do, YYYY') : 'N/A',
      },
      {
        header: 'ORDERED',
        size: 100, //decrease the width of this column
        accessorKey: 'po_ordered',
        accessorFn: (row: any) => CommaNumberFormatter(row?.quantity),
      },
      {
        header: 'RECEIVED',
        size: 90, //decrease the width of this column
        accessorKey: 'po_received',
        accessorFn: (row: any) => CommaNumberFormatter(row?.received),
      },
      {
        header: 'Subtotal',
        size: 100, //decrease the width of this column
        accessorKey: 'po_subtotal',
        accessorFn: (row: any) => SubtotalFormatter(row),
      },
      {
        header: 'Ref. #',
        size: 100, //decrease the width of this column
        accessorKey: 'po_ref#',
        accessorFn: (row: any) => row?.reference,
      },
    ],
    []
  )

  const cbColumnVisibility = useCallback((columnScope: {}) => {
    console.log(columnScope)
    console.log(tableInstanceRef, tableInstanceRef.current?.setColumnVisibility)
    setTableStorage(columnScope)
  }, [])

  const createPoNotes = useCallback(() => {
    const modalData = {
      po_id: id,
      message: '',
      notes: editorRef.current.getContent(),
      type: '',
    }
    createPurchaseOrderNotes(modalData)
      .then(({data}) => {
        getPONotes()
        setCreateModal(false)
        darkMessage('Notes created Successfully')
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          darkErrorMessage(e?.response?.data?.error_list[0])
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
      })
  }, [id, editorRef])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    console.log(event.currentTarget)
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  async function getList(page: number = 0, str = '', pagePerEntries: number = 100) {
    var alterID: any = ''
    alterID = id ? id : jid
    await purchaseOrderItemData(alterID)
      .then((res) => {
        let purchaseOrderData = res?.data?.purchase_order[0]
        let purchaseOrderItems = res?.data?.purchase_order_items
        let forData = res?.data?.purchase_order[0].for_assignee
        setLineItemsCount(res?.data?.po_line_items_count)
        setData(purchaseOrderData || [])
        setFor_assignee(forData || [])
        seTotalRows(res.data.totalRows || [])
        setItems(purchaseOrderItems || [])
      })
      .catch((e) => {
        if (e.response.status === 409) {
          console.log(e.response.data)
        }
      })
    search_routes.find((w) => w === location.pathname) && setRoute(true)
  }
  const company = () => {
    companyDetails()
      .then((res) => {
        setCompanydata(res?.data?.company_data || [])
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const getPONotes = useCallback(() => {
    fetchPurchaseOrderNotes(id)
      .then((res) => {
        setFetchPONotes(res?.data?.notes)
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
      })
  }, [id])
  const getPOFiles = useCallback(() => {
    fetchPurchaseOrderFiles(id)
      .then((res) => {
        setUploadedFiles(res?.data?.response)
        handleDeleteFileModal()
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          console.error(e?.response?.data)
        } else {
          console.error(e)
        }
      })
  }, [id])

  const handleFileDelete = useCallback((idx: any) => {
    setLoading(true)
    const file_id = idx?.id
    deletePurchaseOrderFiles(id, file_id)
      .then((res) => {
        darkMessage(res?.data?.message)
        setLoading(false)
        handleDeleteFileModal()
        getPOFiles()
      })
      .catch((e) => {
        setLoading(true)
        console.error(e)
      })
  }, [])

  const handleFileUpdate = useCallback(
    (idx: any) => {
      setLoading(true)
      const po_data = {
        file_id: idx?.id,
        file_label: fileLabel,
      }

      updatePurchaseOrderFiles(id, po_data)
        .then((res) => {
          darkMessage(res?.data?.message)
          setLoading(false)
          handleUpdateFileModal()
          getPOFiles()
          setFileLabel('')
        })
        .catch((e) => {
          setLoading(true)
          console.error(e)
        })
    },
    [fileLabel]
  )

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Create New Purchase Order',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/inventory/purchase-order`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    getList()
    getPONotes()
    getPOFiles()
    company()
    headerContext.dispatch(stateHeader)
  }, [])
  if (route) {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Create New Purchase Order',
        subtitle: ' ',
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/inventory/purchase-order`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    getList()
    headerContext.dispatch(stateHeader)
  }

  const hoverHandler = (key: any) => {
    setIconShow(true)
    setHoverKey(key)
  }

  const handleDeleteNotes = useCallback(
    (idx: any) => {
      const notesId = idx?.id
      deletePurchaseOrderNotes(id, notesId)
        .then((res) => {
          getPONotes()
          darkMessage('Notes Deleted Successfully')
          handleNotesDeleteModal()
        })
        .catch((e) => {
          if (e?.response?.status === 409) {
            darkErrorMessage('You are not Allowed to Delete Other Employee Notes')
            console.error(e?.response?.data)
          } else {
            console.error(e)
          }
        })
    },
    [id]
  )

  const handleForAssignee = useCallback(
    (idx: any) => {
      console.log(idx)
      const for_id = idx?.id
      deletePurchaseOrderForField(id, for_id)
        .then((res) => {
          console.log(res?.data)
          getList()
          darkMessage('Data deleted Successfully')
          setForDeleteModal({
            show: false,
            index: null,
          })
        })
        .catch((e) => {
          if (e?.response?.status === 409) {
            darkErrorMessage('Error')
            console.error(e?.response?.data)
          } else {
            console.error(e)
          }
        })
    },
    [id]
  )

  const handleUpdateNotes = useCallback(
    (idx: any) => {
      const modalData = {
        notes_id: idx?.id,
        po_id: id,
        message: '',
        notes: editorRef.current.getContent(),
        type: '',
      }
      updatePurchaseOrderNotes(modalData)
        .then((res) => {
          console.log(res)
          getPONotes()

          darkMessage('Notes Updated Successfully')
          handleNotesUpdateModal()
        })
        .catch((e) => {
          if (e?.response?.status === 409) {
            darkErrorMessage('You are not Allowed to Update Other Employee Notes')
            console.error(e?.response?.data)
          } else {
            console.error(e)
          }
        })
    },
    [id]
  )

  const deleteButton = (id: any) => {
    deletePO(id)
      .then((res) => {
        darkMessage('Purchase Order Deleted Successfully')
        navigate(`/${prefixCompany}/inventory/purchase-order`)
        setModal(false)
      })
      .catch((e: any) => {
        console.error(e)
      })
  }

  const onButtonClick = () => {
    inputFile.current.click()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRowClick = (row: any) => {
    const id = row.item || row?.item_sku?.id
    if (id) {
      navigate(`/${prefixCompany}/inventory/store/${id}/view`)
    }
  }

  const generatePdf = useCallback(
    (c_image: any, v_image: any) => {
      const pdf = new jsPDF('portrait', 'pt', 'a4')
      const html = `
    <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8" />
          <link href="https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700;900&display=swap" rel="stylesheet">
          <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap" rel="stylesheet">
        </head>
        <div style="margin-right: 15px; width: 95%">
          <table style="width: 595px; margin-left: 15px; margin-right: 15px">
            <tr>
              <td>
                <h2 style="text-align:center; padding-bottom:0px; width:250px; margin:0px auto; margin-bottom:30px; border-bottom:1px solid #000; margin-top:20px; font-weight:700; font-family: 'Merriweather', serif;">
                Purchase Order : ${data?.id} </h2>
              </td>
            </tr>
          </table>
        </div>
        <div style="width: 595px; margin-left: 15px; font-size: 10px; margin-right: 15px;">
          <table width="100%">
            <tr>
            <td style="vertical-align:top;"><div  style="width:50px; height:50px;" > </div></td>
              <td style="font-size:9px; vertical-align:top; color:#000; font-family: 'Roboto', sans-serif; font-weight:500;">
                <p style="font-size:9px; line-height:11px; font-family: 'Roboto', sans-serif; font-weight:500;">
                  <b>${companydata?.company_name}</b><br />
                  ${companydata?.company_email}<br />
                  ${
                    companydata?.company_phone_number &&
                    companydata?.company_phone_number + '<br />'
                  }
                  ${
                    companydata?.company_address_line1 &&
                    companydata?.company_address_line1 + '<br />'
                  }
                  ${
                    companydata?.company_address_line2 &&
                    companydata?.company_address_line2 + '<br />'
                  }
                  ${companydata?.company_city && companydata?.company_city + ' ,'}
                  ${companydata?.company_state && companydata?.company_state + '<br />'}
                  ${companydata?.company_zip_file && companydata?.company_zip_file + '<br />'}

                </p>
              </td>
              <td style="font-size:9px; vertical-align:top; color:#000; line-height:11px; font-family: 'Roboto', sans-serif; font-weight:500;">
                <p>
                  <b><span style="color:#a4a4a4; font-family: 'Roboto', sans-serif; font-weight:700; font-size:8px;">GENERATED BY</span></b><br />
                  ${currentUser?.first_name + ' ' + currentUser?.last_name}<br />
                  ${currentUser?.phone} <br />
                  ${currentUser?.email}
                </p>
              </td>
              <td style="font-size:9px; vertical-align:top; color:#000; line-height:11px; font-family: 'Roboto', sans-serif; font-weight:500;">
                <p>
                  <b><span style="color:#a4a4a4; font-family: 'Roboto', sans-serif; font-weight:700; font-size:8px;">DATE</span></b><br />
                  ${moment(new Date()).format('MM/DD/YYYY')}
                </p>
              </td>
            </tr>
            <tr>
            <td style="vertical-align:top;"><div  style="width:50px; height:50px;" > </div></td>
              <td style="font-size:9px; vertical-align:top; color:#000; line-height:11px; font-family: 'Roboto', sans-serif; font-weight:500;">
                <p>
                  <b><span style="color:#a4a4a4; font-family: 'Roboto', sans-serif; font-weight:700; font-size:8px;">VENDOR</span></b><br />
                  <b>${data?.vendor?.name}</b><br />
                  ${
                    data?.vendor?.address_line_1 == null
                      ? ''
                      : data?.vendor?.address_line_1 + '<br />'
                  }
                  ${
                    data?.vendor?.address_line_2 == null
                      ? ''
                      : data?.vendor?.address_line_2 + '<br />'
                  }
                  ${data?.vendor?.city == null ? '' : data?.vendor?.city + '<br />'}
                  ${data?.vendor?.state == null ? '' : data?.vendor?.state + '<br />'}
                  ${data?.vendor?.zip == null ? '' : data?.vendor?.zip}
                </p>
              </td>
              <td style="font-size:9px; vertical-align:top; color:#000; line-height:11px; font-family: 'Roboto', sans-serif; font-weight:500;">
                <p>
                  <b><span style="color:#a4a4a4; font-family: 'Roboto', sans-serif; font-weight:700; font-size:8px;">SHIP TO</span></b><br />
                  <b>${companydata?.company_name}</b><br />
                  ${
                    companydata?.company_address_line1 &&
                    companydata?.company_address_line1 + '<br />'
                  }
                  ${
                    companydata?.company_address_line2 &&
                    companydata?.company_address_line2 + '<br />'
                  }
                  ${companydata?.company_city && companydata?.company_city + ' ,'}
                  ${companydata?.company_state && companydata?.company_state + '<br />'}
                  ${companydata?.company_zip_file && companydata?.company_zip_file}
                </p>
              </td>
              <td style="font-size:9px; vertical-align:top; color:#000; line-height:11px; font-family: 'Roboto', sans-serif; font-weight:500;">
                <p>
                  <b><span style="color:#a4a4a4; font-weight:700; font-family: 'Roboto', sans-serif; font-size:8px;">FOR</span></b><br />
                  ${for_assignee
                    .map(
                      (assignee: {work_order: {final_title: any}}) =>
                        assignee.work_order.final_title
                    )
                    .join('<br>')}<br />
                </p>
              </td>
            </tr>
          </table>
        </div>
        <div style="width:595px; margin-left: 15px; font-size: 10px; margin-right: 15px;">
          <table width="100%">
              <thead>
                <tr style="border-bottom:1px solid #a4a4a4; margin-bottom:20px;">
                  <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;">MFG PART #</th>
                  <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;">ITEM NAME</th>
                  <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;">QTY</th>
                  <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;">UNIT COST</th>
                  <th style="color:#a4a4a4; font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;">SUBTOTAL</th>
                </tr>
              </thead>
              <tbody style="margin-top:10px; border-bottom:1px solid #a4a4a4; margin-bottom:20px;">
              ${items.map(
                (item: {
                  item_sku: any
                  item_name: any
                  quantity: any
                  item_cost: any
                  total_cost: any
                }) => `
              <tr style="font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;">
                <td>${item.item_sku?.sku || item?.item_sku}</td>
                <td>${item.item_name}</td>
                <td>${item.quantity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                <td>${`$ ${parseFloat(item?.item_cost).toFixed(2)}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}</td>
                <td>${`$ ${parseFloat(item?.item_cost * item?.quantity).toFixed(2)}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ','
                )}</td>
                </tr>
            `
              )}
              </tbody>
              <tfoot style="padding-top:10px;">
                <tr style="font-weight:500; font-family: 'Roboto', sans-serif; font-size:8px;">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td><b>Total:</b></td>
                  <td>${`$ ${parseFloat(data?.total_cost).toFixed(2)}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ','
                  )}</td>
                </tr>
              </tfoot>
            </table>
          </div>
    </html>
       `

      var img_1 = new Image()
      var img_2 = new Image()

      img_1.onload = function () {
        pdf.addImage(img_1, 'PNG', 20, 80, 60, 60) //base64 image, format, x-coordinate, y-coordinate, width, height
      }

      img_2.onload = function () {
        pdf.addImage(img_2, 'PNG', 20, 170, 60, 60) //base64 image, format, x-coordinate, y-coordinate, width, height
      }
      img_1.crossOrigin = ''
      img_2.crossOrigin = ''
      img_1.src = API_URL + c_image
      img_2.src = API_URL + v_image

      pdf.html(html).then(() => {
        pdf.save('purchaseOrder.pdf')
        setPdfLoading(false)
        setTimeout(async () => {
          const result = await deletePdfTempFiles(c_image, v_image)
          console.log(result?.data)
        }, 1000)
      })
    },
    [
      data?.id,
      data?.user?.email,
      data?.vendor?.name,
      data?.vendor?.address_line_1,
      data?.vendor?.address_line_2,
      data?.vendor?.city,
      data?.vendor?.state,
      data?.vendor?.zip,
      data?.total_cost,
      companydata?.company_name,
      companydata?.company_phone_number,
      companydata?.company_email,
      companydata?.company_address_line1,
      companydata?.company_address_line2,
      companydata?.company_city,
      companydata?.company_state,
      companydata?.company_zip_file,
      currentUser?.first_name,
      currentUser?.last_name,
      currentUser?.phone,
      currentUser?.email,
      for_assignee,
      items,
    ]
  )

  const handlePdfGenerate = useCallback(async () => {
    setPdfLoading(true)
    console.log(data?.vendor?.id)
    await savePdfTempFiles(data?.vendor?.id)
      .then((res) => {
        const company_logo = res?.data?.company_file
        const vendor_logo = res?.data?.vendor_file
        generatePdf(company_logo, vendor_logo)
      })
      .catch((e) => {
        if (e?.response?.status === 409) {
          darkErrorMessage(e?.response?.data?.error_list[0])
        }
        console.error(e)
        setPdfLoading(false)
      })
  }, [data?.vendor?.id, generatePdf])
  return (
    <>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        centered
        show={pdfLoading}
        className='w-100 h-100 bg-black'
      >
        <ModalBody className='text-center'>
          <CircularProgress color='info' />
          <h1 className='mt-5'>Generating PDF....</h1>
        </ModalBody>
      </Modal>
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          setModal(false)
        }}
        label={'somelable'}
        show={modal}
        onHide={() => setModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <div className='p-5'>
          <h2 className='p-3'>Delete Purchase Order</h2>
          <hr></hr>
          <p className='p-3'>
            Are you sure want to delete Purchase Order <b> #{id} </b> ?
          </p>
          <div className='d-flex flex-row-reverse mx-2 m-3'>
            <span className='mx-2'>
              <Button
                onClick={() => {
                  deleteButton(id)
                }}
              >
                Delete
              </Button>
            </span>
            <span className='mx-1'>
              <Button
                onClick={() => {
                  setModal(false)
                }}
              >
                Cancel
              </Button>
            </span>
          </div>
        </div>
      </Modal>

      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          setCreateModal(false)
        }}
        show={createModal}
        className='fade'
        onHide={() => setCreateModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='xl'
      >
        <Modal.Header>
          <Modal.Title>
            <h1>Create New Notes</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <Editor
            apiKey='0eft9hxkqsjsqk6k7ktmr2souml1bokfgct065bjayhaadex'
            onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex flex-row-reverse mx-2 m-3'>
            <span className='mx-2'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  createPoNotes()
                }}
              >
                Add Notes
              </button>
            </span>
            <span className='mx-1'>
              <button
                onClick={() => {
                  setCreateModal(false)
                }}
                className='btn btn-dark'
              >
                Cancel
              </button>
            </span>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          setForModal(false)
        }}
        show={forModal}
        className='fade'
        onHide={() => setForModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='xl'
      >
        <Modal.Header>
          <Modal.Title>
            <h1> PO-{id}</h1>
          </Modal.Title>
        </Modal.Header>
        <PurchaseOrderFor
          closeModal={() => setForModal(false)}
          getList={() => getList()}
          editRecord={for_assignee}
        />
      </Modal>
      <DeleteModal
        modalShow={deleteNotesModal?.modal}
        title={'Confirmation Message'}
        body={'Did You really want to delete this Note ?'}
        handleClose={() => handleNotesDeleteModal()}
        confirmFunction={() => {
          handleDeleteNotes(deleteNotesModal?.data)
        }}
      />

      <DeleteModal
        modalShow={forDeleteModal?.show}
        title={'Confirmation Message'}
        body={'Did you really want to delete assignee ?'}
        handleClose={() =>
          setForDeleteModal({
            show: false,
            index: null,
          })
        }
        confirmFunction={() => handleForAssignee(forDeleteModal?.index)}
      />
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          handleUpdateFileModal()
        }}
        show={updateFileModal?.show}
        className='fade'
        onHide={() => handleUpdateFileModal()}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='xl'
      >
        <Modal.Header>
          <Modal.Title>
            <h1>Update File Label</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <div id='kt_account_profile_details' className='collapse show'>
            <div className='row mb-6'>
              <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                Enter File Name
              </label>
              <div className='col-lg-9 fv-row'>
                <input
                  type='text'
                  required
                  value={fileLabel}
                  onChange={(e) => setFileLabel(e.target.value)}
                  className='form-control form-control-lg form-control-solid'
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex flex-row-reverse mx-2'>
            <span className='mx-2'>
              <button
                className='btn btn-primary'
                disabled={loading}
                onClick={() => {
                  handleFileUpdate(updateFileModal?.data)
                }}
              >
                {!loading && 'Confirm'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </span>
            <span className='mx-1'>
              <button
                onClick={() => {
                  handleUpdateFileModal()
                }}
                className='btn btn-dark'
              >
                Discard
              </button>
            </span>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          handleDeleteFileModal()
        }}
        show={deleteFileModal?.show}
        className='fade'
        onHide={() => handleDeleteFileModal()}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='xl'
      >
        <Modal.Header>
          <Modal.Title>
            <h1>Confirmation Message</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <h3>Did You really want to delete this File ?</h3>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex flex-row-reverse mx-2'>
            <span className='mx-2'>
              <button
                className='btn btn-primary'
                disabled={loading}
                onClick={() => {
                  handleFileDelete(deleteFileModal?.data)
                }}
              >
                {!loading && 'Confirm'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </span>
            <span className='mx-1'>
              <button
                onClick={() => {
                  handleDeleteFileModal()
                }}
                className='btn btn-dark'
              >
                Discard
              </button>
            </span>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          setNewModal(false)
        }}
        show={newModal}
        className='fade'
        onHide={() => setNewModal(false)}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='xl'
      >
        <Modal.Header>
          <Modal.Title>
            <h1>Confirmation Message</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <h3>Did You really want to upload this File ?</h3>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex flex-row-reverse mx-2'>
            <span className='mx-2'>
              <button
                className='btn btn-primary'
                disabled={loading}
                onClick={() => {
                  filesUploadFormik.submitForm()
                }}
              >
                {!loading && 'Confirm'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </span>
            <span className='mx-1'>
              <button
                onClick={() => {
                  filesUploadFormik.resetForm()
                }}
                className='btn btn-dark'
              >
                Discard
              </button>
            </span>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        onModalClose={() => {
          handleNotesUpdateModal()
        }}
        show={updateNotesModal?.modal}
        className='fade'
        onHide={() => handleNotesUpdateModal()}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size='xl'
      >
        <Modal.Header>
          <Modal.Title>
            <h1>Update Notes</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {' '}
          <Editor
            apiKey='0eft9hxkqsjsqk6k7ktmr2souml1bokfgct065bjayhaadex'
            onInit={(evt, editor) => (editorRef.current = editor)}
            initialValue={updateNotesModal?.data?.message || ''}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex flex-row-reverse mx-2'>
            <span className='mx-2'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  handleUpdateNotes(updateNotesModal?.data)
                }}
              >
                Confirm
              </button>
            </span>
            <span className='mx-1'>
              <button
                onClick={() => {
                  handleNotesUpdateModal()
                }}
                className='btn btn-dark'
              >
                Discard
              </button>
            </span>
          </div>
        </Modal.Footer>
      </Modal>

      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem onClick={() => handlePdfGenerate()}>Generate PDF</MenuItem>
        <Divider />
        {HAS_PurchaseOrderUpdatePO(currentUser) ? (
          <>
            <MenuItem
              onClick={() => {
                navigate(`/${prefixCompany}/inventory/purchase-order/edit/${id}`)
              }}
            >
              Update Order
            </MenuItem>
            <Divider />
          </>
        ) : null}
        {HAS_PurchaseOrderDeletePO(currentUser) ? (
          <MenuItem
            onClick={() => {
              setModal(true)
            }}
          >
            Delete Order
          </MenuItem>
        ) : null}
      </Menu>
      {/* {HAS_PurchaseOrderViewPO(currentUser) ? ( */}
      <div className='container p-0'>
        <div className='row'>
          {jio !== 0 ? null : (
            <div className='col-lg-12'>
              <div className='purchase-order-card-header'>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className='card-upperside'>
                          <table>
                            <tr>
                              <td className='c-w10'>
                                <div className='d-flex justify-content-center'>
                                  <a
                                    href={`${
                                      data?.vendor_logo
                                        ? data?.vendor_logo
                                        : '/media/avatars/blank.png'
                                    }`}
                                    target='blank'
                                    style={{padding: '5px'}}
                                  >
                                    <img
                                      alt='emp img'
                                      src={`${
                                        data?.vendor_logo
                                          ? data?.vendor_logo
                                          : '/media/avatars/blank.png'
                                      }`}
                                      onError={(evt: any) => {
                                        evt.target.src = blank
                                      }}
                                      style={{objectFit: 'contain'}}
                                      width='100px'
                                      height={'50px'}
                                    />
                                  </a>
                                </div>
                              </td>
                              <td className='c-w10'>
                                <div className='card-content-desc'>
                                  <span>PO#</span>
                                  <p>PO-{id}</p>
                                </div>
                              </td>
                              <td className='c-w10'>
                                <div className='card-content-desc'>
                                  <span>Vendor</span>
                                  <p>{data.vendor?.name}</p>
                                </div>
                              </td>
                              <td className='c-w12'>
                                <div className='card-content-desc'>
                                  <span>Vendor Order Number</span>
                                  <p>{data.vendor_ordernumber || 'N/A'}</p>
                                </div>
                              </td>
                              <td className='c-w16'>
                                <div className='card-content-desc'>
                                  <span className='d-block'>
                                    For
                                    <i
                                      className='bi bi-pencil-square mx-2 cursor-pointer'
                                      style={{color: 'black'}}
                                      onClick={() => setForModal(true)}
                                    ></i>
                                  </span>
                                  {for_assignee.map((item: any, key: number) => {
                                    return (
                                      <>
                                        <div className='d-inline-block position-relative'>
                                          <span
                                            className={`position-absolute ${
                                              forIconShow === true && forHoverKey === key
                                                ? 'd-block'
                                                : 'd-none'
                                            }`}
                                          >
                                            <i
                                              className='bi bi-x-circle position-absolute top-2 start-100 translate-middle'
                                              style={{
                                                top: '-6px',
                                                color: 'red',
                                                marginLeft: '-8px',
                                              }}
                                              onClick={() =>
                                                setForDeleteModal({
                                                  show: true,
                                                  index: item,
                                                })
                                              }
                                            ></i>
                                          </span>
                                          {item?.work_order?.final_title}
                                          <br />
                                          <span className='italic-text '>
                                            {item?.customer?.company}
                                          </span>
                                        </div>
                                        <br />
                                      </>
                                    )
                                  })}
                                </div>
                              </td>
                              <td className='c-w12'>
                                <div className='card-content-desc'>
                                  {/* <div className='dropdown-select'> */}
                                  <div>
                                    <span>Order Date</span>
                                    <p style={{fontSize: '14px'}}>
                                      <span className='text-dark'>
                                        {moment(data?.date_ordered).format('MMM. Do, YYYY')}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td className='c-w12'>
                                <div className='card-content-desc'></div>
                              </td>
                              <td className='text-right'>
                                <div className='card-content-desc mx-5 d-flex align-items-center'>
                                  <button
                                    className='additional-details-btn px-3 py-2'
                                    onClick={() => setDropdownFunction((prevState) => !prevState)}
                                  >
                                    Additional Details{' '}
                                    <img
                                      alt='drop_down icon'
                                      src='/media/logos/up-chevron.png'
                                    ></img>
                                  </button>
                                  <Tooltip title='Customize PO' className='bg-secondary'>
                                    <IconButton
                                      onClick={(e) => handleClick(e)}
                                      size='small'
                                      className='p-2'
                                      sx={{ml: 2, borderRadius: '2'}}
                                      aria-controls={open ? 'account-menu' : undefined}
                                      aria-haspopup='true'
                                      aria-expanded={open ? 'true' : undefined}
                                    >
                                      <i className='fa-solid fa-ellipsis'></i>
                                      {/* <KTSVG path='/media/icons/duotune/arrows/arr082.svg' /> */}
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </td>
                            </tr>
                            {dropdownFunction && (
                              <tr className='border-top-1 border-secondary'>
                                <td>
                                  {/* <div className='card-content-desc opacity0'>
                        <span>Items Ordered</span>
                        <p>{data?.item_count}</p>
                      </div> */}
                                </td>
                                <td>
                                  <div className='card-content-desc' style={{marginTop: '10px'}}>
                                    <span>Items Received</span>
                                    <p>0</p>
                                  </div>
                                </td>
                                <td>
                                  <div className='card-content-desc' style={{marginTop: '10px'}}>
                                    <span>Shipping Method</span>
                                    <p>{data.shipping_method?.name || 'N/A'}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className='card-content-desc' style={{marginTop: '10px'}}>
                                    <span>Tracking Number</span>
                                    <p>{data?.tracking_number || 'N/A'}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className='card-content-desc' style={{marginTop: '10px'}}>
                                    <span>Shipping Cost</span>
                                    <p>{NumberFormatter(data?.shipping_cost)}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className='card-content-desc' style={{marginTop: '10px'}}>
                                    <span>Tax</span>
                                    <p>{NumberFormatter(data?.tax)}</p>
                                  </div>
                                </td>
                                <td>
                                  <div className='card-content-desc' style={{marginTop: '10px'}}>
                                    <span>PO Total</span>
                                    <p>{NumberFormatter(data?.total_cost)}</p>
                                  </div>
                                </td>
                                <td></td>
                              </tr>
                            )}
                          </table>
                        </div>
                      </td>
                    </tr>
                    {/* <tr>
                  <td>
                    <div className='card-lowerside'>
                      <table>
                        
                      </table>
                    </div>
                  </td>
                </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          <div className={`col-xl-${len} col-lg-12 col-sm-12`}>
            <div className='card mb-5 mb-xl-10'>
              <Modal
                style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
                show={receiveModal}
                aria-labelledby='contained-modal-title-vcenter'
                centered
                size={'xl'}
                onHide={() => {
                  setReceivedModal(false)
                }}
              >
                <div className='p-5'>
                  <h2>Receive Order PO-{id}</h2>
                </div>
                <PurchaseOrderView
                  recretM={recretM}
                  pdata={true}
                  jio={1}
                  jid={id}
                  updateTable={getList}
                />
              </Modal>
              <div className='border-top'>
                {jio !== 0 ? (
                  <>
                    <DataTable pointerOnHover highlightOnHover columns={columns} data={items} />
                    <div className='col-12 d-flex flex-row-reverse mt-7 '>
                      <Button
                        type='submit'
                        style={{marginRight: '13px', width: 150}}
                        onClick={handleSub}
                      >
                        {pdata ? 'Receive Items' : 'Return Order'}
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <Box>
                      <MaterialDataTable
                        MaterialColumns={MaterialColumns}
                        MaterialColumnsApiData={items}
                        stopRedirection={false}
                        totalRows={totalRows}
                        tableHeight={700}
                        loadingBar={loading}
                        serverPagination={pagination}
                        setServerPagination={(cb_data: any) => {
                          return PaginationCallback(cb_data)
                        }}
                        columnVisibility={tableStorage}
                        cbColumnVisibility={(callbackData) => {
                          cbFunction(callbackData)
                        }}
                        tableInstanceRef={tableInstanceRef}
                        optionalTableHeader={
                          <Toolbar
                            sx={(theme) => ({
                              display: 'flex',
                              justifyContent: 'space-between',
                            })}
                          >
                            <Box>
                              <div
                                className='card-header border-0 cursor-pointer'
                                role='button'
                                data-bs-toggle='collapse'
                                data-bs-target='#kt_account_profile_details'
                                aria-expanded='true'
                                aria-controls='kt_account_profile_details'
                              >
                                <div className='card-title m-0'>
                                  <h3 className='m-3'>Ordered Items ({lineItemsCount})</h3>
                                </div>
                              </div>
                            </Box>
                            <Box>
                              <div className='badges-block'>
                                <span
                                  className={
                                    data?.order_status === 'Partially Received'
                                      ? 'delivers-badge'
                                      : data?.order_status === 'Received'
                                      ? 'receive-item-badge me-3'
                                      : data?.order_status === 'IN_TRANSIT'
                                      ? 'delivery-badge'
                                      : ''
                                  }
                                >
                                  <img src='/media/logos/delivery.png'></img>
                                  {data?.order_status && data?.order_status.replace(/_/g, ' ')}
                                </span>
                                <span
                                  className='receive-item-badge me-3'
                                  onClick={() => setReceivedModal(true)}
                                >
                                  <img src='/media/logos/order.png'></img>
                                  Receive Items
                                </span>
                              </div>
                            </Box>
                          </Toolbar>
                        }
                      />
                    </Box>
                  </>
                )}
              </div>
            </div>
          </div>

          {jio !== 0 ? null : (
            <div className='col-xl-3 col-lg-12 col-md-12 notes-block-wrapper'>
              <div className='notes-block'>
                <div className='notes-header'>
                  <h3>Notes</h3>
                  <button
                    className='add-note border-0'
                    onClick={() => {
                      setCreateModal(true)
                    }}
                  >
                    <img src='/media/logos/notes.png'></img>
                    <h6>Add Note</h6>
                  </button>
                </div>
                <div className='chat-box' style={{height: '250px', overflow: 'scroll'}}>
                  {loading ? (
                    <LinearProgress />
                  ) : fetchPONotes.length > 0 ? (
                    fetchPONotes.map((eachNote: any) => {
                      return (
                        <div className='chat-box-description my-2 '>
                          <div className='chat-box-description-header justify-content-between align-items-start'>
                            <div className='profile-image d-flex me-2'>
                              <img
                                src={eachNote?.user_image}
                                style={{
                                  marginRight: '10px',
                                }}
                              />
                              <div>
                                <h5>
                                  <span className='fs-2 '>
                                    {eachNote?.user?.first_name + ' ' + eachNote?.user?.last_name}
                                  </span>
                                </h5>
                                <p>{moment(eachNote?.created_at).format('llll')}</p>
                              </div>
                            </div>
                            <div className='profile-desc'>
                              <div>
                                <span className='text-muted pe-lg-5'>
                                  {eachNote?.type || 'General Note'}
                                  <strong className='cursor-pointer float-end'>
                                    {HAS_PO_Notes_Edit(currentUser, eachNote?.user) && (
                                      <>
                                        <i
                                          className='bi bi-pencil-square mr-2 cursor-pointer'
                                          style={{color: 'black'}}
                                          onClick={() => {
                                            setUpdateNotesModal({
                                              modal: true,
                                              data: eachNote,
                                            })
                                          }}
                                        ></i>
                                        <span
                                          className='cursor-pointer'
                                          onClick={() => {
                                            setDeleteNotesModal({
                                              modal: true,
                                              data: eachNote,
                                            })
                                          }}
                                        >
                                          <KTSVG path={'/media/custom/trash.svg'} />
                                        </span>
                                      </>
                                    )}
                                  </strong>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className='chat-box-description-body'>
                            <div dangerouslySetInnerHTML={{__html: eachNote?.message}}></div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className='text-center'>
                      <h6>There are no Notes to Display</h6>
                    </div>
                  )}
                </div>
              </div>
              <div className='upload-block'>
                <div className='upload-header'>
                  <h3>Attached files</h3>
                  <button className='add-upload border-0' onClick={onButtonClick}>
                    <img src='/media/logos/upload.png'></img>
                    <h6>Upload Files</h6>
                  </button>
                  <input
                    type='file'
                    onChange={(e) => {
                      try {
                        let filesToUpload: any = []
                        if (e?.target?.files != null) {
                          Object.values(e.target.files).map((value) => {
                            return filesToUpload.push(value)
                          })
                          filesUploadFormik.setFieldValue('selected_files', filesToUpload)
                        }
                      } catch (e) {
                        console.error(e)
                      }
                    }}
                    value={photo}
                    name='file'
                    id=''
                    style={{display: 'none'}}
                    ref={inputFile}
                    multiple
                  />
                </div>
                <div className='pdf-list' style={{height: '250px', overflow: 'scroll'}}>
                  {loading ? (
                    <LinearProgress />
                  ) : uploadedFiles.length > 0 ? (
                    uploadedFiles.map((eachFile: any, key: any) => {
                      return (
                        <div
                          style={{padding: '2px', fontSize: '15px', color: 'cornflowerblue'}}
                          className='pointer-cursor d-flex justify-content-between'
                          onMouseOver={() => hoverHandler(key)}
                          onMouseOut={() => setIconShow(false)}
                        >
                          <div>
                            <a href={eachFile?.file_path} target='blank'>
                              {eachFile?.file_name || eachFile?.file_path}
                            </a>
                          </div>

                          <div
                            className={`cursor-pointer ${
                              iconShow === true && hoverKey === key ? 'd-block' : 'd-none'
                            }`}
                          >
                            <i
                              className='bi bi-pencil-square mx-1'
                              style={{color: 'red'}}
                              onClick={() => {
                                setUpdateFileModal({
                                  show: true,
                                  data: eachFile,
                                })
                              }}
                            ></i>
                            <i
                              className='bi bi-x-circle mx-1'
                              style={{color: 'black'}}
                              onClick={() => {
                                setDeleteFileModal({
                                  show: true,
                                  data: eachFile,
                                })
                              }}
                            ></i>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className='text-center'>
                      <h6>There are no Files to Display</h6>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* ) : null} */}
      <Modal
        style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
        show={recieveEditModal}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        size={'xl'}
        onHide={() => {
          setReceivedEditModal(false)
        }}
      >
        <div className='border-top'>
          <div className='p-5'>
            <h2>Receive Order PO-{id}</h2>
          </div>
          <DataTable pointerOnHover highlightOnHover columns={columns} data={items} />
          <div className='col-12 d-flex flex-row-reverse mt-7 '>
            <Button
              type='submit'
              style={{marginRight: '13px', width: 150}}
              onClick={handleRecivedSub}
            >
              Update Received
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
export {PurchaseOrderView}
