import React, {useContext, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useAuth} from '../../modules/auth'
import {HeaderAction, HeaderContext} from '../../context/HeaderContext'
import {currnetCompanyName, darkMessage, updatePassword} from '../../modules/auth/core/_requests'
import ErrorAlert, {ErrorType} from '../../modules/errors/ErrorAlert'
import {useFormik} from 'formik'
import SuccessAlert, {SuccessType} from '../../modules/errors/SuccessAlert'
import {useNavigate} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Modal} from 'react-bootstrap'

const changePassordSchema = Yup.object().shape({
  newpass: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmnewpass: Yup.string()
    .required('Password confirmation is required')
    .when('newpass', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('newpass')], "Password and Confirm Password didn't match"),
    }),
})

const AccountSetting: React.FC = () => {
  const {currentUser, logout} = useAuth()
  const headerContext = useContext<any>(HeaderContext)
  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<SuccessType>()
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false)
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const initialValues = {
    newpass: '',
    confirmnewpass: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: changePassordSchema,
    onSubmit: async (values) => {
      const password = values?.newpass
      setLoading(true)
      try {
        updatePassword(password)
          .then((res) => {
            setSuccessBody(res?.data as any)
            formik.resetForm()
            setLoading(false)
            setConfirmationModal(true)
            // darkMessage('Password update successfully')
            // navigate(`/${prefixCompany}/dashboard`)
          })
          .catch((e) => {
            if (e.response.status == 409) {
              setErrorBody(e.response.data)
              setLoading(false)
            }
          })
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    const stateHeader = {
      payload: {
        custom: true,
        title: 'Account Settings',
        subtitle: ` `,
        btn_text: (
          <KTSVG path={'/media/icons/duotune/arrows/arr079.svg'} className={'svg-icon-1'} />
        ),
        navigation_url: `/${prefixCompany}/dashboard`,
        additional_btn_text: ' ',
      },
      type: HeaderAction.Update,
    }
    headerContext.dispatch(stateHeader)
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <Modal
          style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}
          show={confirmationModal}
          className='fade'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Header>
            <div className='row align-items-center m-auto'>
              <img
                alt='Logo'
                style={{objectFit: 'contain', maxWidth: '200px'}}
                className='logo m-auto'
                src={toAbsoluteUrl('/media/logos/Blu Portal Wide Logo Blue.png')}
              />
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className='d-flex row justify-content-center'>
              <h1 className='text-center my-lg-9'>Password is changed</h1>
              <div className='row align-items-center my-lg-9'>
                <img
                  alt='Logo'
                  width={'400px !important'}
                  height={'150px'}
                  className='logo'
                  src={toAbsoluteUrl('/media/custom/password_changed.svg')}
                />
              </div>
              <div className='text-center my-lg-9 '>
                <span className=''>
                  <button
                    className='btn btn-primary'
                    onClick={() => {
                      logout()
                    }}
                  >
                    Sign In
                  </button>
                </span>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <div className='d-block m-lg-9'>
          <h3 className='fw-bolder m-0'>Account Settings</h3>
        </div>
      </div>
      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>New Password</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='password'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Password'
                  {...formik.getFieldProps('newpass')}
                />
                {formik.touched.newpass && formik.errors.newpass && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.newpass}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Confirm New Password</span>
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='password'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Confirm Password'
                  {...formik.getFieldProps('confirmnewpass')}
                />
                {formik.touched.confirmnewpass && formik.errors.confirmnewpass && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.confirmnewpass}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
export {AccountSetting}
