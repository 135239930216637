/* eslint-disable no-sparse-arrays */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { viewQuote } from '../../../modules/auth/core/_requests'
import moment from 'moment'
import { LinearProgress } from '@mui/material'
import { ProfileContext } from '../../../../_metronic/layout/MasterLayout'
export function TimeSheet() {
  const [data, setData] = useState<any>([])
  const [result, setResult] = useState<any>([])
  const { params, id } = useParams()
  const profileContext: any = useContext(ProfileContext)
  const [loading, setLoading] = useState<Boolean>(false)

  async function getList() {
    setLoading(true)
    await viewQuote(id)
      .then((res) => {
        try {
          let fields = res.data.total_hours
          setData(fields?.data || [])
          // console.log(fields)
          setResult(fields || [])
        } catch (error) { }
      })
      .catch((e) => {
        console.log(e)
      })
    setLoading(false)
  }

  useEffect(() => {
    getList()
  }, [profileContext?.state?.clockIn])

  const dateDifference = (e: number) => {
    const time = e / 3600
    return parseFloat(time.toFixed(2))
  }

  return (
    <>
      {loading && <LinearProgress />}
      <div className='card p-5'>
        <table
          style={{
            fontFamily: 'Poppins, Helvetica, "sans-serif"',
            borderCollapse: 'collapse',
            borderRadius: '2px',
            width: '100%',
            background: 'white',
            fontSize: '14px',
          }}
          className='shadow'
        >
          <tr>
            <td
              style={{ borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}
              className='text-dark fw-bold'
            >
              Employee Name
            </td>
            <td
              style={{ borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}
              className='text-dark fw-bold'
            >
              Department
            </td>
            <td
              style={{ borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}
              className='text-dark fw-bold'
            >
              Total Time
            </td>
          </tr>
          {Object.keys(data).map((value: any) => {
            return (
              <>
                <tr>
                  <td
                    style={{ borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}
                  >
                    {value || ''}
                  </td>
                  <td
                    style={{ borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}
                  >
                    {data[value]['department'] || ''}
                  </td>
                  <td
                    style={{ borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}
                  >
                    {!isNaN(dateDifference(data[value]['timeDiff'])) ? dateDifference(data[value]['timeDiff']) : 0}
                  </td>
                </tr>
              </>
            )
          })}
          <tr>
            <td
              style={{
                borderBottom: '1px solid #dddddd',
                textAlign: 'left',
                padding: '8px',
                color: '#0088FF',
              }}
            // colSpan={3}
            >
              Grand Total
            </td>
            <td
              style={{ borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}
              className='text-dark'
            ></td>
            <td
              style={{ borderBottom: '1px solid #dddddd', textAlign: 'left', padding: '8px' }}
              className='text-dark'
            >
              {!isNaN(dateDifference(result?.sum)) ? dateDifference(result?.sum) : 0}
            </td>
          </tr>
        </table>
      </div>
    </>
  )
}
