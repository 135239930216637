import {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  addQuote,
  currnetCompanyName,
  darkMessage,
  updateQuoteData,
  viewQuote,
} from '../../modules/auth/core/_requests'
import {useNavigate, useParams} from 'react-router-dom'
import {useAuth} from '../../modules/auth'
import ErrorAlert, {ErrorType} from '../../modules/errors/ErrorAlert'
import SuccessAlert, {SuccessType} from '../../modules/errors/SuccessAlert'
import {QuoteStepper} from '../../context/QuoteContext'
import fields_step from '../../data/quoteV3.json'
import clsx from 'clsx'
import {Editor} from '@tinymce/tinymce-react'
import {onKeyDown} from './GlobalComponents'
import {QuotesModel} from '../../modules/model/quotes'

let validationSchemaObject: any = {}
let initialValues: any = {}
const fields_data = fields_step.step2 as any
Object.keys(fields_step.step2).forEach((item: any) => {
  let field = fields_data[item]
  initialValues[item] = item?.default_value
  field?.nullable ? (
    (validationSchemaObject[item] = Yup.string().required(field?.label + ' is required'))
  ) : (
    <></>
  )
})

const validationSchema = Yup.object().shape(validationSchemaObject)
const UpdateQuoteModal = ({type, onCloseUpdateQuoteModal}: any) => {
  const renderModalContent = () => {
    if (type === 'description') {
      return (
        // Render description field content here
        <div className='card-body border-top p-9'>
          {Object.keys(fields_step.step2).map((key: string) => {
            let field = fields_data[key]
            return (
              <>
                {field?.type === 'select' ? (
                  <></>
                ) : field?.type === 'textarea' ? (
                  // <div className='row mb-12' key={key}>
                  //   <label
                  //     className={`col-lg-12 col-form-label fw-bold fs-6 ${
                  //       field?.nullable ? 'required' : ''
                  //     }`}
                  //   >
                  //     {field.label}
                  //   </label>
                  //   <div className='col-lg-12 fv-row'>
                  //     <Editor
                  //       apiKey='0eft9hxkqsjsqk6k7ktmr2souml1bokfgct065bjayhaadex'
                  //       onInit={(evt, editor) => (editorRef.current = editor)}
                  //       onEditorChange={(stringifiedHtmlValue) => {
                  //         formik.setFieldValue(key, stringifiedHtmlValue)
                  //       }}
                  //       init={{
                  //         height: 500,
                  //         menubar: false,
                  //         plugins: [
                  //           'advlist autolink lists link image charmap print preview anchor',
                  //           'searchreplace visualblocks code fullscreen',
                  //           'insertdatetime media table paste code help wordcount',
                  //         ],
                  //         toolbar:
                  //           'undo redo | formatselect | ' +
                  //           'bold italic backcolor | alignleft aligncenter ' +
                  //           'alignright alignjustify | bullist numlist outdent indent | ' +
                  //           'removeformat | help',
                  //         content_style:
                  //           'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  //       }}
                  //       {...formik.getFieldProps(key)}
                  //     />
                  //     {/* <textarea
                  //     rows='10'
                  //     disabled={loading}
                  //     className={clsx(
                  //       'form-control form-control-lg ',
                  //       {
                  //         'is-invalid': formik.touched[key] && formik.errors[key],
                  //       },
                  //       {
                  //         'is-valid': formik.touched[key] && !formik.errors[key],
                  //       }
                  //     )}
                  //   /> */}
                  //     {formik.touched[key] && formik.errors[key] && (
                  //       <div className='fv-plugins-message-container'>
                  //         <div className='fv-help-block'>
                  //           <span role='alert'>{formik.errors[key]}</span>
                  //         </div>
                  //       </div>
                  //     )}
                  //   </div>
                  // </div>
                  <></>
                ) : (
                  <div className='row mb-12' key={key}>
                    <label
                      className={`col-lg-12 col-form-label fw-bold fs-6 ${
                        field?.nullable ? 'required' : ''
                      }`}
                    >
                      {field.label}
                    </label>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        disabled={loading}
                        className={clsx(
                          'form-control form-control-lg ',
                          {
                            'is-invalid': formik.touched[key] && formik.errors[key],
                          },
                          {
                            'is-valid': formik.touched[key] && !formik.errors[key],
                          }
                        )}
                        {...formik.getFieldProps(key)}
                      />
                      {formik.touched[key] && formik.errors[key] && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors[key]}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )
          })}

          <div className='row text-end'>
            <div className='col-md-12 text-right'>
              <button type='submit' disabled={loading} className='btn btn-lg btn-primary me-3'>
                <span className='indicator-label'>{!loading && 'Save Changes'}</span>
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      )
    } else if (type === 'text') {
      return (
        // Render text field content here
        <div className='card-body border-top p-9'>
          {Object.keys(fields_step.step2).map((key: string) => {
            let field = fields_data[key]
            return (
              <>
                {field?.type === 'select' ? (
                  <></>
                ) : field?.type === 'textarea' ? (
                  <div className='row mb-12' key={key}>
                    <label
                      className={`col-lg-12 col-form-label fw-bold fs-6 ${
                        field?.nullable ? 'required' : ''
                      }`}
                    >
                      {field.label}
                    </label>
                    <div className='col-lg-12 fv-row'>
                      <Editor
                        apiKey='0eft9hxkqsjsqk6k7ktmr2souml1bokfgct065bjayhaadex'
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        onEditorChange={(stringifiedHtmlValue) => {
                          formik.setFieldValue(key, stringifiedHtmlValue)
                        }}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                          ],
                          toolbar:
                            'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                          content_style:
                            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        }}
                        {...formik.getFieldProps(key)}
                      />
                      {/* <textarea
                        rows='10'
                        disabled={loading}
                        className={clsx(
                          'form-control form-control-lg ',
                          {
                            'is-invalid': formik.touched[key] && formik.errors[key],
                          },
                          {
                            'is-valid': formik.touched[key] && !formik.errors[key],
                          }
                        )}
                      /> */}
                      {formik.touched[key] && formik.errors[key] && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors[key]}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                  // <div className='row mb-12' key={key}>
                  //   <label
                  //     className={`col-lg-12 col-form-label fw-bold fs-6 ${
                  //       field?.nullable ? 'required' : ''
                  //     }`}
                  //   >
                  //     {field.label}
                  //   </label>
                  //   <div className='col-lg-12 fv-row'>
                  //     <input
                  //       type='text'
                  //       disabled={loading}
                  //       className={clsx(
                  //         'form-control form-control-lg ',
                  //         {
                  //           'is-invalid': formik.touched[key] && formik.errors[key],
                  //         },
                  //         {
                  //           'is-valid': formik.touched[key] && !formik.errors[key],
                  //         }
                  //       )}
                  //       {...formik.getFieldProps(key)}
                  //     />
                  //     {formik.touched[key] && formik.errors[key] && (
                  //       <div className='fv-plugins-message-container'>
                  //         <div className='fv-help-block'>
                  //           <span role='alert'>{formik.errors[key]}</span>
                  //         </div>
                  //       </div>
                  //     )}
                  //   </div>
                  // </div>
                )}
              </>
            )
          })}

          <div className='row text-end'>
            <div className='col-md-12 text-right'>
              <button type='submit' disabled={loading} className='btn btn-lg btn-primary me-3'>
                <span className='indicator-label'>{!loading && 'Save Changes'}</span>
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      )
    } else {
      // Return a default content or handle unsupported type
      return <div>jj</div>
    }
  }
  const qsContext: any = useContext(QuoteStepper)
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const {currentUser} = useAuth()
  const {id} = useParams()
  const prefixCompany = currnetCompanyName(currentUser?.company)
  const editorRef: any = useRef(null)

  const [errorBody, setErrorBody] = useState<ErrorType>()
  const [successBody, setSuccessBody] = useState<SuccessType>()
  const [customerDetails, setCustomerDetails] = useState<object | any>({})
  const [data, setData] = useState<any>([])

  const getList = useCallback(async () => {
    setLoading(true)
    await viewQuote(id)
      .then((res) => {
        let fields = res.data.customer_details
        setCustomerDetails(res.data.customer_details.customer)
        setData(fields?.fields)
        setLoading(false)
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
      })
  }, [id])

  useEffect(() => {
    getList()
  }, [getList])

  useEffect(() => {
    formik.setFieldValue('project_field', data?.project_field)
    formik.setFieldValue('description', data?.description)
  }, [data])

  const formik: any = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const params = []
      const modalData = {
        customer_id: customerDetails?.id,
        workorder_id: id,
        description: values.description,
        project_field: values.project_field,
      }
      params.push(modalData)
      setLoading(true)
      updateQuoteData(params)
        .then(({data}) => {
          getList()
          setLoading(false)
          setSuccessBody(data as any)
          formik.resetForm()
          onCloseUpdateQuoteModal()
          darkMessage('Quote updated Successfully')
          // navigate(`/${prefixCompany}/quote`)
        })
        .catch((e) => {
          // if (e.response.status == 409) {
          //   setErrorBody(e.response.data)
          //   setLoading(false)
          // }
          getList()
          console.log(e)

          setLoading(false)
        })
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.QUOTE'})} </PageTitle>
      <div className='card mb-12 mb-xl-12'>
        <div id='kt_account_profile_details' className='collapse show'>
          {errorBody && <ErrorAlert error_response={errorBody} />}
          {successBody && <SuccessAlert success_response={successBody} />}
          <form onSubmit={formik.handleSubmit} noValidate className='form' onKeyDown={onKeyDown}>
            <div> {renderModalContent()}</div>
          </form>
        </div>
      </div>
    </>
  )
}
export {UpdateQuoteModal}
